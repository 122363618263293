.popular-destination-container {
    padding: 0 !important;

    .title-popular-destinations {
        font-family: Poppins-Bold, sans-serif;
        text-align: center;
    }

    .grid-wrapper {

        &.desktop-section {
            display: none;
        }

        .country-list {
            margin-top: 25px;
            margin-bottom: 25px;

            .cover {
                margin: 0 auto 15px;
                align-items: center;
                overflow: hidden;
                max-width: 450px;

                .country-item {
                    width: 100%;
                    align-items: center;
                    margin-left: auto;
                    margin-right: auto;
                    position: relative;

                    .subst {
                        position: absolute;
                        z-index: 3;
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-color: transparent;
                        top: 57%;
                        left: 50%;
                        transform: translate(-50%, -60%);
                        border: none;
                    }

                    .img-content {
                        width: 100%;
                        height: 100%;

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .name-city {
                        margin: 0;
                        transform: translate(-50%, -50%);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 100%;
                        align-items: center;
                        text-align: center;
                        z-index: 1;
                        color: var(--color-neutral-lightest);
                        font-family: Poppins-SemiBold, sans-serif;
                        font-weight: bold;
                        font-size: 14px;
                        text-transform: uppercase;
                    }

                    .country-item-label {
                        display: block;
                        font: 300 18px 'Poppins-Light', sans-serif;
                        text-align: center;
                        padding: 5px 15px;

                        &.d-none {
                            display: none;
                        }
                    }
                }
            }
        }

        .btn-load-more {
            display: block;
            width: fit-content;
            font: 700 20px 'Poppins-Bold', sans-serif;
            color: var(--color-accent-gold);
            margin: 0 auto;
            background: transparent;
            border: none;
        }
    }

    .button-area {
        width: 185px;
        height: 40px;
        margin-top: 30px;
        margin-right: auto;
        margin-left: auto;

        .a-btn {
            width: 100%;
            text-align: center;
            font-size: 14px;
        }
    }
}