.search-tool {
    padding: 30px 15px;
    height: auto;
    width: auto;

    .search-section-container {
        background: var(--color-supportive);
        border-radius: 19px;
        flex-wrap: nowrap;
        max-width: 925px;
        max-height: 72px;
        margin: 0px auto;
        box-shadow: 0px 3.6px 3.6px rgba(0, 0, 0, 0.25);

        &.scrolling {
            position: fixed;
            top: calc(var(--nav-bar-height) + 10px);
            z-index: 1000;
            left: 50%;
            transform: translateX(-50%);
            width: 95%;
        }

        &.scale-animation {
            animation: scaleAnimation 2s ease-in-out forwards;
        }

        @keyframes scaleAnimation {
            0% {
                transform: translateX(-50%) scale(1);
            }

            50% {
                transform: translateX(-50%) scale(1.2);
            }

            100% {
                transform: translateX(-50%) scale(1);
            }
        }

        .location-area {
            height: auto;
            margin: 0;
            flex: none;
            width: 80%;
            padding: 0;
            z-index: 1;

            .location-icon {
                position: relative;
                top: auto;
                left: auto;
                display: none;
            }

            input {
                font-size: 13px;
                color: var(--color-text);
                padding: 3px 0 3px 10px;
                background: var(--color-supportive);
                border-radius: 19px 0 0 19px;

                &::placeholder {
                    font-size: 13px;
                    padding: 0;
                    text-align: left;
                }
            }
        }

        .button-search-area {
            height: auto;
            margin: 0;
            flex: none;
            width: 20%;

            button {
                font-size: 11px;
                background: var(--color-primary);
                color: var(--color-neutral-lightest);
                border-top-right-radius: 19px;
                border-bottom-right-radius: 19px;
                border: none;

                &:active, &:hover {
                    border: none;
                    background: var(--color-accent-gold);
                    color: var(--color-primary);
                }
            }
        }

        .search-results {
            width: 100%;
            top: 55%;
            left: 0;
            margin: 0;
            z-index: -1;
            border-radius: 0 0 19px 19px;

            &__list {
                background: var(--color-supportive);
            }

            &__list-item {
                &:first-child {
                    a {
                        padding-top: 30px;
                    }
                    
                    &::before {
                        top: 30px;
                    }
                }

                a {
                    padding: 13px 10px 13px 50px;
                }

                &::before {
                    mask: url($search-form-sprite + '#location') no-repeat;
                    mask-size: contain;
                    width: 20px;
                    height: 22px;
                    top: 14px;
                    left: 19px;
                    background: var(--color-primary);
                }

                &.selected,
                &:focus,
                &:hover,
                &:active {
                    &::before {
                        background: var(--color-accent-gold);
                    }
                }

            }
        }
    }
}

@media screen and (min-width: 768px) {
    .search-tool {
        padding: 50px 15px;

        .search-section-container {
            border-radius: 35px;
            font-size: 22px;

            .location-area {
    
                input {
                    font-size: 22px;
                    padding: 16px 0 16px 25px;
                    border-radius: 35px 0 0 35px;
    
                    &::placeholder {
                        font-size: 22px;
                    }
                }
            }

            .search-results {
                border-radius: 0 0 35px 35px;

                &__list-item {
                    &:first-child {
                        a {
                            padding-top: 50px;
                        }
                        
                        &::before {
                            top: 53px;
                        }
                    }

                    &::before {
                        width: 24px;
                        height: 29px;
                        top: 20px;
                    }
    
                    a {
                        padding: 18px 10px 18px 50px;
                    }
                }
            }

            .button-search-area {
                width: 20%;
    
                button {
                    font-size: 22px;
                    border-top-right-radius: 35px;
                    border-bottom-right-radius: 35px;
                }
            }
        }
    }
}