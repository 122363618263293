.experience-carousel {
    margin: 0 !important;

    .slick-arrow {
        display: none !important;
    }

    .experience-slider {
        margin-bottom: 0 !important;
        margin-top: 25px !important;
    }

    .slider-item {
        width: 300px !important;
        height: auto !important;
        display: flex;
        flex-direction: column;

        .img-content {
            flex: 1;
        }

        .text-content {
            flex: 1;
        }
    }

    @media screen and (min-width: 992px) {
        .slick-track {
            width: 100% !important;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
        }

        .slider-item {
            width: calc(100% / 3 - 20px)!important;
        }
    }
}
