@import '../../common-variables';

.home-page-hero-img {
    position: relative;
    background: transparent;
    padding-right: 0;

    .logo-img-container {
        position: relative;
        padding-top: 90px;
        width: 100%;
        height: 100%;
        z-index: 2;
        padding-left: 30px;

        .video-block {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
    
            .video-box {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
    
                .play-button {
                    background: transparent;
                    border: none;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 40px;
                    height: 40px;
                    padding: 0;
                    
                    i {
                        display: block;
                        width: 100%;
                        height: 100%;
                        mask: url($homepage-sprite + '#play-button') no-repeat center;
                        background-color: var(--color-neutral-lightest);
                        mask-size: cover !important;
                    }
                }
            }
        }

        .logo-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
        }

        .mobile-block {
            display: block;
        }

        .desktop-block {
            display: none;
        }
    }

    .animation-img {
        position: absolute;
        top: 15px;
        right: 40px;
        background: var(--color-primary-light);
        mask: url($homepage-sprite + '#buildings') no-repeat center;
        mask-size: cover !important;
        width: 100px;
        height: 100px;
        z-index: 1;

        animation: maskChange 16s infinite steps(1);
    }

    @keyframes maskChange {
        0%, 12.5% {
            mask: url($homepage-sprite + '#buildings') no-repeat center;
        }
        12.5%, 25% {
            mask: url($homepage-sprite + '#ramen') no-repeat center;
        }
        25%, 37.5% {
            mask: url($homepage-sprite + '#bikes') no-repeat center;
        }
        37.5%, 50% {
            mask: url($homepage-sprite + '#door') no-repeat center;
        }
        50%, 62.5% {
            mask: url($homepage-sprite + '#kimono') no-repeat center;
        }
        62.5%, 75% {
            mask: url($homepage-sprite + '#tower') no-repeat center;
        }
        75%, 87.5% {
            mask: url($homepage-sprite + '#church') no-repeat center;
        }
        87.5%, 100% {
            mask: url($homepage-sprite + '#lamps') no-repeat center;
        }
    }

    .title {
        position: relative;
        padding-bottom: 10px;
        padding-right: 40px;
        margin: -60px 0 0 -30px;

        h1, h2 {
            background: var(--color-neutral-lightest);
            background-clip: text;
            color: var(--color-text);
            word-wrap: break-word;
        }

        h1 {
            font-size: 35px;
            -webkit-text-stroke: 12px transparent;
            margin-bottom: 0;
            padding: 5px 0;
        }

        h2 {
            font-size: 18px;
            -webkit-text-stroke: 7px transparent;
            font-family: 'Poppins-Medium';
            padding: 5px 0;
        }

    }
}

@media screen and (min-width: 768px) {
    .home-page-hero-img {
        padding: 0 15px;

        .logo-img-container {
            padding-left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
    
            .logo-img {
                border-radius: 100px;
                max-height: 500px;
                max-width: 1020px;
            }

            .video-block {
                .video-box {
                    .play-button {
                        width: 80px;
                        height: 75px;
                    }
                }
            }
        }
    
        .animation-img {
            position: absolute;
            top: 25px;
            right: 40px;

            @media screen and (min-width: 1200px) {
                width: 250px;
                height: 250px;
                right: 0;
            }
        }
    
        .title {
            position: absolute;
            left: 0;
            margin: 0;
            max-width: 600px;
    
            h1 {
                font-size: 50px;
            }
    
            h2 {
                font-size: 25px;
            }
    
        }
    }
}