.section-customer-reviews {
    background-color: var(--blue-basic);
    color: var(--white);
    font: 400 13px 'Poppins-Regular', sans-serif;
    padding: 30px 0;
    margin-bottom: 155px;

    .title-box {
        text-align: center;

        .title {
            color: var(--white);
            margin-bottom: 4px;
        }
    }
    
    .icon {
        display: block;
        background-color: var(--white);
        mask-size: cover !important;
        flex-shrink: 0;
    }

    .icon-tripadvisor-rating {
        width: 93px;
        height: 18px;
        mask: url($customer-reviews-section-sprite + '#icon-tripadvisor-rating2') no-repeat center;
    }

    .icon-google-rating {
        width: 94px;
        height: 18px;
        mask: url($customer-reviews-section-sprite + '#icon-google-rating') no-repeat center;
    }

    .icon-google {
        width: 27px;
        height: 27px;
        mask: url($customer-reviews-section-sprite + '#icon-google') no-repeat center;
    }

    .icon-tripadvisor {
        width: 36px;
        height: 25px;
        mask: url($customer-reviews-section-sprite + '#icon-tripadvisor') no-repeat center;
    }

    .customer-reviews-block {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 40px;
        font-size: 9px;

        .customer-reviews-item {
            display: flex;
            align-items: flex-start;
            gap: 10px;
            max-width: 160px;

            .statistic-block {
                .icon {
                    margin-bottom: 5px;
                }
            }
        }
    }

    .autentic-stamp-block {
        margin: 20px 0 -60px;
        position: relative;
        display: flex;
        justify-content: center;

        .icon-stamp {
            width: 90px;
            height: 90px;
            display: block;
            background: url($customer-reviews-section-sprite + '#authentic-stamp') center no-repeat;
            background-size: cover;
        }

        .stamp-description {
            position: absolute;
            bottom: -100px;
            color: var(--color-text);
            font: 500 15px Architects-Daughter, sans-serif;
            max-width: 270px;
            text-align: center;
            margin-left: 90px;

            &:before {
                content: '';
                display: block;
                width: 60px;
                height: 20px;
                mask: url($customer-reviews-section-sprite + '#arrow3') no-repeat center;
                background-color: var(--color-text);
                mask-size: cover !important;
                transform: rotate(90deg);
                position: absolute;
                top: -44px;
                left: 5px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .section-customer-reviews {
        font-size: 18px;
        padding: 50px 0 50px;
        margin-bottom: 115px;

        .icon-tripadvisor-rating,
        .icon-google-rating {
            width: 180px;
            height: 34px;
        }

        .icon-tripadvisor {
            width: 90px;
            height: 60px;
        }

        .icon-google {
            width: 60px;
            height: 60px;
        }

        .title-box {
            margin-bottom: 40px;
        }

        .customer-reviews-block {
            font-size: 18px;
    
            .customer-reviews-item {
                gap: 15px;
                max-width: 360px;
            }
        }

        .autentic-stamp-block {
            margin-bottom: -110px;

            .icon-stamp {
                width: 150px;
                height: 150px;
            }

            .stamp-description {
                left: 50%;
                bottom: -50px;
                margin-left: 130px;
                font-size: 17px;

                &:before {
                    width: 100px;
                    height: 35px;
                    top: 15px;
                    left: -105px;
                    transform: rotate(0);
                }
            }
        }
    }
}