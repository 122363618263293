.section-featured-experiences {
    padding: 30px 0;

    .title {
        text-align: center;
        padding: 0 55px;
    }

    .subtitle {
        text-align: center;
        display: none;
        margin-bottom: 30px;
    }

    .swiper {
        width: 100%;
        padding: 10px 0;
    }

    .experience-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: var(--color-neutral-lighter);
        width: 80%;
        max-width: 310px;
        min-height: 230px;
        border-radius: 10px;
        box-shadow: 0 6px 7px 0 rgba(0, 0, 0, .25);
        position: relative;

        a {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        img {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            max-height: 116px;
            width: 100%;
            object-fit: cover;
        }

        .card-info {
            padding: 0 15px;
            display: flex;
            gap: 15px;
            position: relative;
            width: 100%;
            flex: auto;

            .city {
                position: absolute;
                right: -10%;
                top: -55%;
                color: var(--color-primary);
                background: var(--color-accent-gold);
                mask: url($experience-card-sprite + '#city-background') no-repeat center;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 85px;
                height: 85px;
                font-size: 16px;
                font-family: Architects-Daughter, sans-serif;;
            }

            .experience-title {
                padding: 20px 5px 20px 0;
                margin-bottom: 0;
                width: 80%;
                font-size: 15px;
                word-break: normal;
                word-wrap: normal;
                position: relative;
                font-family: Poppins-SemiBold, sans-serif;

                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 2%;
                    width: 6px;
                    height: 95%;
                    background-color: var(--color-primary);
                    mask: url($experience-card-sprite + '#dashed-line') no-repeat center;
                    mask-size: cover;
                }
            }

            .info-block {
                padding: 20px 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 8px;
                font-size: 10px;
                font-family: Poppins, sans-serif;
                width: 30%;
                position: relative;

                &-row {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }

                .icon {
                    display: block;
                    width: 15px;
                    height: 15px;
                }

                .star {
                    background: var(--color-accent-gold);
                    mask: url($experience-card-sprite + '#star-yellow') no-repeat center;
                }

                .price-tag {
                    background: var(--color-primary);
                    mask: url($experience-card-sprite + '#price-tag') no-repeat center;
                }

                .clock {
                    background: var(--color-primary);
                    mask: url($experience-card-sprite + '#clock') no-repeat center;
                }
            }
        }
    }
}


@media screen and (min-width: 768px) {
    .section-featured-experiences {
        .title {
            font-size: 29px;
            margin-bottom: 20px;
        }

        .subtitle {
            display: block;
            font-size: 25px;
        }

        .experience-card {
            width: 60%;
            max-width: 590px;
            min-height: 365px;
    
            img {
                max-height: 225px;
            }
    
            .card-info {
                .city {
                    width: 100px;
                    height: 100px;
                    font-size: 18px;
                }
    
                .experience-title {
                    font-size: 26px;
                }
    
                .info-block {
                    font-size: 15px;
                    padding-left: 3%;

                    .icon {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .section-featured-experiences {
        padding: 50px 0;

        .experience-card {
            min-height: 410px;

            .card-info {
                .city {
                    width: 120px;
                    height: 120px;
                    font-size: 20px;
                }
    
                .experience-title {
                    font-size: 28px;
                }
    
                .info-block {
                    font-size: 20px;

                    .icon {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }
    }
}
