@import '../../common-variables.scss';

// ===== header
@import 'hero-img';

// ===== main
@import '../../sections/popular-destination-mobile';
@import '../../web-push-popup-home';
@import '../../sections/featured-experiences-section';
@import '../../search-tool';
@import 'search-section';
@import '../../sections/section-authentic-experiences';
@import '../../sections/section-as-seen-in';
@import '../../section-customer-reviews';
@import './section-featured-experiences';
@import '../../sections/host-common/host-details-popup';
@import '../../sections/host-common/section-hosts';
@import '../../../../../library/swiper/swiper-bundle.min.css';
@import '../../sections/travel-difference-section';
@import '../../sections/travelers-love-section';
@import '../../sections/section-our-guests-comments';
@import '../../sections/what-excites-grid-section';
@import '../../sections/section-customer-stories';
@import '../../sections/plan-your-experience-block';
@import '../../sections/reviews-mobile';
@import '../../icons-library';

@media screen and (min-width: 768px) {
    @import '../../sections/popular-destination-desktop';
    @import '../../sections/reviews-desktop';
}

.section-customer-stories {
    .customer-stories-slider {
        padding-left: 0;
    }

    .title-box {
        justify-content: flex-start;
    }
}

.seacrch-placeholder {
    height: 0;
    overflow: hidden;
}