@import "./common-variables";

@mixin icon-mask($icon) {
  mask: url("#{$icons-library-sprite}##{$icon}") no-repeat center;
}

$icons: adjust, advanced, architecture, art, art-gallery, backpack, beer, bus,
  cake, cancellation, car, categories, checklist, cityscape, cloudy-day, compass,
  confused, culture, customization, deal, discount, duration, enter-attraction,
  experience, family, ferry, film, flexible-time, food-money, food-allergies,
  food-asia, food-italy, generic-food, gin, hidden-gem, highlights, hiking,
  history, hoodie, host, host-expertise, host-selection, hotel, info, insights,
  knowledge, lake, language, leader, market, meeting-point, metro,
  multiple-locations, nature, nature-exploration, night-food, night, night-mode,
  payment, pet-friendly, photography, private, questionnaire, relaxation, review,
  safety, sake, seasonal, shop, shopping, sunset, sustainable, tailored, taxi,
  tequila, tickets, tips, tourist-attraction, train, umbrella, vegetarian,
  walking, wheelchair, whiskey, wine;

@each $icon in $icons {
  .#{$icon} {
    @include icon-mask($icon);
  }
}
