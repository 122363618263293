.push-popup-container {
    position: fixed;
    border-radius: 10px;
    top: auto;
    bottom: 0;
    left: 0;
    position: fixed;
    flex-direction: column;
    width: 100%;
    padding: 15px 0;
    background-color: rgba(255,255,255,.5);
    backdrop-filter: blur(25px);
    box-shadow: 0 -5px 13px 0 rgba(0,0,0,.25);
    justify-content: center;
    align-items: center;
    height: 25%;
    z-index: 99999;

    .popup-header{
        margin-bottom: 0;
        height: 25%;
        display: flex;
        align-items: center;

        span {
            font-size: 16px;
            margin: 0 auto;
            text-align: center;
        }
    }

    .popup-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 75%;
        width: 100%;

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px ;
            padding: 5px 30px;
            transition: 0.3s;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 16px;

            &.reject-button {
                border: none;
                color: var(--color-primary);
                background-color: transparent;
                text-decoration: underline;

                &:active,
                &:hover,
                &:focus{
                    border-color: var(--yellow-basic);
                    color: var(--yellow-basic);
                }
            }

            &.allow-button {
                border: 2px solid var(--color-primary);
                color: var(--color-primary);
                background-color: var(--color-neutral-lightes);

                &:active,
                &:hover,
                &:focus {
                    color: var(--color-neutral-lightes);
                    background-color: var(--color-primary);
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .push-popup-container {
        position: fixed;
        top: 0;
        left: 6.5%;
        width: auto;
        background-color: var(--color-neutral-lightes);
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 99999;
        height: 120px;

        .popup-header {
            display: flex;
            align-items: center;
            margin: 0;

            .bell-icon {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }

            span {
                font-size: 14px;
            }
        }

        .popup-buttons {
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px; 
        }
    }
}