@import './common-variables.scss';

.search-tool {
    background-color: var(--color-neutral-lightest);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-top: 15px;
    margin: 0;
    width: 100%;
    height: 100%;

    .search-section-container {
        display: flex;
        flex-wrap: wrap;
        min-width: 285px;
        margin-top: 15px;

        .location-area, .people-area, .button-search-area {
            display: flex;
            width: 100%;
            height: 60px;
            flex: 0 1 100%;
            margin: 0 25px 10px 25px;
        }

        .location-area {
            position: relative;
            padding: 0 1px 0 33px;
            align-items: center;

            i {
                content: '';
                position: absolute;
                top: 15px;
                left: 13px;
                display: inline-block;
                width: 45px;
                height: 28px;
                z-index: 3;

                @media screen and (min-width: 768px) {
                    left: -15px;
                }
            }

            .location-icon {
                background: url($search-form-sprite + '#icon-search_yellow') no-repeat;
                background-size: contain;
            }

            input {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                display: inline-block;
                width: 100%;
                border: 0;
                color: var(--color-primary);
                background-color: transparent;
                font-family: Poppins, sans-serif;
                font-weight: normal;
                font-size: 16px;
                line-height: 31px;
                padding-left: 18px;
                position: relative;
                z-index: 2;

                @media screen and (min-width: 768px) {
                    padding-left: 36px;
                    font-size: 14px;
                }

                &::placeholder {
                    color: var(--color-primary);
                    text-align: center;
                    font-size: 16px;
                }
            }

            .input-wrapper {
                position: relative;
                width: 100%;
            
                .top-placeholder,
                .bottom-placeholder {
                    position: absolute;
                    left: 30px;
                    color: var(--color-primary);

                }

                .top-placeholder {
                    top: 5px;
                    font-family: Poppins-Bold, sans-serif;
                    font-size: 10px;
                }
        
                .bottom-placeholder {
                    top: 18px;
                    font-family: Poppins-Medium, sans-serif;
                    font-size: 8px;
                }
            }
        }

        .people-area {
            align-items: center;
            flex-wrap: nowrap;
            position: relative;
            justify-content: center;

            .people-area-text {
                font-family: Poppins, sans-serif;
                width: 100%;
                text-align: center;
                cursor: pointer;
            }

            .arrow-down-yellow {
                position: absolute;
                mask: url($search-form-sprite + '#icon-arrow-down') no-repeat;
                mask-size: contain;
                background: var(--color-accent-gold);
                width: 25px;
                height: 25px;
                right: 5px;
                top: 13px;
                cursor: pointer;

                &:hover {
                    background: var(--color-primary);
                }
            }

            .people-area-dropdown {
                position: absolute;
                display: flex;
                bottom: -141px;
                background-color: var(--white);
                width: 100%;
                flex-direction: column;
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
                padding: 10px 0;
                z-index: 999;
                border-radius: 25px;
                padding: 10px 25px 30px;
                height: fit-content;
                min-width: 400px;

                @media screen and (min-width: 1024px) {
                    min-width: 530px;
                }

                .people-item {
                    border-bottom: 1px solid var(--color-neutral-light);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: var(--color-primary);
                    padding: 15px 0;

                    .people-title {
                        font-family: Poppins-SemiBold, sans-serif;
                        font-size: 15px;

                        &-additional {
                            font-family: Poppins-Regular, sans-serif;
                            color: var(--color-primary-light);
                        }
                    }

                    .amount-people {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-family: Poppins-Medium, sans-serif;
                        font-size: 16px;
                    }

                    button {
                        width: 20px;
                        height: 20px;
                        border: none;
                        background-size: contain;
                        border: 2px solid var(--color-accent-gold);
                        color: var(--color-accent-gold);
                        background: transparent;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        line-height: 1;

                        &:disabled {
                            color: var(--color-neutral-light);
                            border-color: var(--color-neutral-light);
                        }
                    }
                }
            }
        }

        .button-search-area {

            button {
                width: 100%;
                border: 1px solid var(--color-accent-gold);
                background-color: var(--color-accent-gold);
                color: var(--color-primary);
                letter-spacing: 0.75px;
                text-align: center;
                justify-content: center;
                align-items: center;
                font-family: Poppins-Medium, sans-serif;
                font-size: 16px;
                font-weight: normal;

                &:hover,
                &:focus,
                &:active {
                    border: 1px solid var(--color-accent-gold);
                    background-color: var(--color-primary);
                    color: var(--color-accent-gold);
                }
            }

            .book {
                display: none;
            }

            .find {
                display: none;
            }
        }

        .search-results {
            display: flex;
            position: absolute;
            width: 40%;
            max-height: 160px;
            border: 0;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            background-color: var(--white);
            overflow: auto;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
            z-index: 3;
            margin: 60px 2px 0 26px;

            &__list {
                width: 100%;
                &-item {
                    text-align: start;
                    position: relative;
                    transition: 0.5s;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 11px;
                        left: 23px;
                        display: block;
                        width: 21px;
                        height: 13px;
                        mask: url($search-form-sprite + '#icon-marker_yellow') no-repeat;
                        mask-size: contain;
                        background: var(--color-accent-gold);

                        @media screen and (min-width: 768px) {
                            left: 24px;
                        }
                    }

                    a {
                        display: block;
                        padding: 10px 0 5px 48px;
                        font-family: Poppins, sans-serif;
                        color: var(--color-primary);
                        text-decoration: none;

                        @media screen and (min-width: 768px) {
                            padding: 10px 0 5px 60px;
                        }

                        &:focus,
                        &:hover,
                        &:active {
                            background-color: var(--color-primary);
                            color: var(--white);
                        }
                    }

                    &.selected,
                    &:focus,
                    &:hover,
                    &:active {
                        background-color: var(--color-primary);

                        a {
                            color: var(--white);
                        }
                    }

                    &:hover {
                        background-color: var(--color-primary);

                        a {
                            color: var(--white);
                        }
                    }
                }
            }

            .not-found {
                font-family: Poppins, sans-serif;
                padding: 5px 0 5px 55px;
                color: var(--color-primary);
                font-size: 9px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .search-tool {
        background-color: var(--color-neutral-lightest);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-top: 0;
        align-items: center;
        width: 550px;
        margin: 0 auto;

        .search-section-container {
            flex-wrap: nowrap;
            min-width: initial;
            width: 100%;
            margin-top: 0;
            font-size: 14px;

            @media screen and (min-width: 992px) {
                font-size: 16px;
            }

            .location-area, .people-area, .button-search-area {
                height: 50px;
                margin: 10px;
                border: none;

                .area-title {
                    display: flex;
                    position: absolute;
                    background-color: var(--white);
                    padding: 0 5px;
                    top: -18px;
                    font-family: Poppins-Light, sans-serif;
                    font-size: 14px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: fit-content;

                    .location-icon {
                        position: initial;
                        width: 20px;
                        height: 20px;
                    }

                    span {
                        padding: 0 5px;
                    }
                }
            }

            .people-area {
                .people-area-dropdown {
                    bottom: -141px;
                    flex-direction: column;
                    padding: 10px 30px 30px;
        
                    .people-item {
                        .people-title, .amount-people {
                            font-size: 17px;
                        }
    
                        button {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }
            }

            .location-area {
                position: relative;
                flex: 0 1 66%;
                padding: 0 1px 0 15px;

                i {
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: 16px;
                    width: 55px;
                    height: 28px;
                }

                input {
                    font-size: 14px;
                    line-height: 28px;
                    padding-left: 45px;

                    &::placeholder {
                        color: var(--color-primary);
                        text-align: left;
                        font-size: 16px;
                    }

                    @media screen and (min-width: 992px) {
                        font-size: 16px;
                    }
                }

                .input-wrapper {

                    .top-placeholder,
                    .bottom-placeholder {
                        left: 51px;
                
                        &.top-placeholder {
                            top: 0;
                            font-size: 15px;
                        }
                
                        &.bottom-placeholder {
                            top: 18px;
                            font-size: 12px;
                        }
                    }
                }
            }

            .button-search-area {
                flex: 0 1 34%;
                letter-spacing: 1.05px;
                margin-right: 20px;

                button {
                    font-size: 14px;

                    @media screen and (min-width: 992px) {
                        font-size: 16px;
                    }
                }
            }

            .search-results {
                position: absolute;
                max-height: 320px;
                border: 0;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
                background-color: var(--white);
                overflow: auto;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
                margin: 69px 0 0 0;

                &__list {
                    &-item {
                        &:before {
                            width: 47px;
                            left: 36px;
                        }

                        a {
                            padding: 10px 0 5px 65px;
                        }
                    }
                }

                .not-found {
                    font-size: 15px;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .search-tool {
        width: 600px;

        .search-section-container {
            font-size: 16px;

            .button-search-area {
                button {
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {

    .search-tool {
        top: 395px;
        height: 80px;

        .search-section-container {
            max-width: 1080px;

            .location-area, .people-area, .button-search-area {
                height: 60px;
            }

            .location-area {

                i {
                    top: 13px;
                    left: 28px;
                    width: 65px;
                    height: 30px;
                }

                input {
                    padding-left: 60px;
                    font-size: 16px;

                    &::placeholder {
                        font-size: 16px;
                    }
                }
            }

            .button-search-area {

                button {
                    font-size: 16px;
                }
            }
        }
    }
}