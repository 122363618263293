.section-authentic-experiences {
    background-color: var(--color-primary);
    color: var(--color-neutral-lightest);
    padding: 30px 0;
    text-align: center;
    font: 400 17px 'Poppins-Medium', sans-serif;
    line-height: 1.5;

    .title-block {
        margin-bottom: 50px;
    }

    .title {
        color: var(--color-accent-gold);
    }

    .authentic-experiences-list {
        .list-item {
            margin-bottom: 30px;
        }
    }

    .card-authentic-experiences {
        display: flex;
        flex-direction: column;
        align-items: center;
        font: 400 21px 'Poppins-Medium', sans-serif;

        .card-img {
            width: 66px;
            height: 66px;
            border-radius: 50%;
            overflow: hidden;
            padding: 11px;
            margin-bottom: 15px;
            flex-shrink: 0;
            background: var(--color-neutral-lightest);
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                display: block;
                width: 66px;
                height: 66px;
            }

            .icon-food {
                background: var(--color-primary);
                mask: url($homepage-sprite + '#icon-food') center no-repeat;
                mask-size: 300% auto;
            }

            .icon-discover {
                background: var(--color-primary);
                mask: url($homepage-sprite + '#icon-discover') center no-repeat;
                mask-size: 300% auto;
            }
        }

        p {
            &:last-child {
                margin: 0;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .section-authentic-experiences {
        text-align: left;
        padding: 75px 0;

        .content-block {
            display: flex;
            justify-content: space-between;
            gap: 60px;
        }

        .title-block {
            width: 55%;
        }

        .authentic-experiences-list {
            width: 45%;

            .list-item {
                margin-bottom: 50px;
            }
        }

        .card-authentic-experiences {
            flex-direction: row;

            .card-img {
                margin: 0 20px 0 0;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .section-authentic-experiences {
        .title {
            font-size: 44px;
        }

        .content-block {
            gap: 95px;
        }

        .card-authentic-experiences {
            font-size: 24px;

            .card-img .icon {
                width: 91px;
                height: 91px;
                padding: 15px;
            }
        }
    }
}