@import 'icons';

.section-hosts {
    padding: 50px 0;

    .container-l {
        padding: 0 0 0 15px;
    }

    .title-box {
        text-align: center;
    }

    .hosts-slider {  
        padding-bottom: 50px;

        .slick-track {
            display: flex;
            align-items: stretch;
            padding: 15px 0 15px 5px;
            height: 100%;
        }

        .slider-item {
            display: flex;
            margin-right: 15px;
            flex-grow: 1;
            height: auto !important;
        }

        .slick-arrow {
            top: auto;
            bottom: 0;
            height: 30px;
            width: 30px;
            border-radius: 0;
            padding: 0;

            &:hover {
                &::after {
                    background-color: var(--color-accent-gold);
                }
            }

            &::after {
                height: 30px;
                width: 30px;
                background-color: var(--color-primary);
                mask: url($host-details-popup + '#icon-arrow-down') no-repeat center;
                mask-size: cover;
                margin: 0;
                transition: background-color 0.3s linear;
            }

            &.slick-next {
                right: 50%;
                transform: rotate(-90deg) translateY(130%);
            }

            &.slick-prev {
                left: 50%;
                transform: rotate(90deg) translateY(130%);
            }
        }
    }

    .card-host-info {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        background: var(--grey-light-basic);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        overflow: hidden;
        color: var(--base-blue);
        font: 13px 'Poppins-Regular', sans-serif;
        height: 100%;
        width: 100%;
        transition: .3s;
    
        &:hover {
            color: var(--base-blue);
            transform: scale(1.02);
        }
    
        .card-img {
            border-radius: 10px;
            overflow: hidden;
            height: 200px;
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    
        .card-category {
            position: absolute;
            top: -15px;
            left: 1px;
            max-width: 98%;
    
            .icon-category {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 2px solid var(--white);
                position: absolute;
                left: -1px;
                top: 50%;
                transform: translateY(-50%);
                background-color: var(--color-primary-light);
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            .label-category {
                font-size: 11px;
                color: var(--white);
                background-color: var(--color-primary-light);
                border-radius: 10px;
                padding: 5px 10px 4px 45px;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }
        }
    
        .card-body {
            padding: 25px 10px 15px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
    
            .description {
                margin-bottom: 10px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 3.6em;
                line-height: 1.2em;
            }
        }
    
        .card-title {
            margin-bottom: 5px;
        }
    
        .reviews-block {
            display: flex;
            align-items: center;
            gap: 2px;
            font: 400 12px 'Poppins-Regular', sans-serif;
    
            .icon-star {
                width: 15px;
                height: 15px;
                display: block;
                background-color: var(--color-primary-light);
                mask: url($host-details-popup + '#star_yellow') no-repeat center;
                mask-size: cover;
            }
    
            .reviews-count {
                color: #2E7D82;
            }

            .reviews {
                padding: 0;
                color: var(--color-primary-light);
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .section-hosts {
        .hosts-slider {
            padding-bottom: 70px;

            .slick-arrow {
                height: 40px;
                width: 40px;

                &::after {
                    height: 40px;
                    width: 40px;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .section-hosts {
        .hosts-slider {
            padding-bottom: 80px;

            .slider-item {
                margin-right: 25px;
            }

            .slick-arrow {
                height: 48px;
                width: 48px;

                &::after {
                    height: 48px;
                    width: 48px;
                }
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .section-hosts {
        .card-host-info {
            font-size: 16px;

            .card-img {
                height: 230px;
            }

            .card-category {
                .icon-category {
                    width: 45px;
                    height: 45px;
                }

                .label-category {
                    font-size: 14px;
                    padding: 6px 10px 5px 50px;
                }
            }

            .card-body {
                padding: 30px 15px 15px;

                .description {
                    margin-bottom: 15px;
                }
            }

            .card-title {
                margin-bottom: 10px;
            }

            .reviews-block {
                font-size: 18px;
                gap: 6px;

                .icon-star {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
