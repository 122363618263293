.what-excites-grid-section {
    padding: 30px 0;

    .title-block {
        text-align: center;
        margin-bottom: 30px;

        .title {
            margin-bottom: 20px;
        }

        .title-comment {
            font: 500 16px Architects-Daughter, sans-serif;
            position: relative;
            width: 200px;
            padding-right: 50px;
            margin: 0 auto;
            text-align: left;
            transform: rotate(-3deg);

            .icon-arrow {
                width: 76px;
                height: 80px;
                display: block;
                background-color: var(--color-accent-rose);
                mask: url($homepage-sprite + '#arrow_1') no-repeat center;
                mask-size: cover;
                position: absolute;
                right: -10px;
                top: -5px;
            }
        }
    }

    .grid-card {
        position: relative;
        border-radius: 20px;
        overflow: hidden;

        &:hover,
        &:focus-within {
            .card-text {
                opacity: 0;
                visibility: hidden;
            }

            .card-description {
                opacity: 1;
                visibility: visible;
            }
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(180deg, rgba(30, 30, 30, 0.00) 0%, rgba(30, 21, 3, 0.35) 90.5%);
        }

        .card-text {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            padding: 5px 15px;
            background-color: var(--color-primary-light);
            color: var(--color-neutral-lightest);
            margin-bottom: 0;
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: opacity 0.3s linear, visibility 0.3s linear;
            font-family: Poppins, sans-serif;
        }

        .card-img {
            max-height: 170px;
            height: 100%;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .card-description {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            padding: 15px;
            background: rgba(11, 16, 55, 0.6);
            color: var(--color-neutral-lightest);
            font-size: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s linear, visibility 0.3s linear;

            p {
                margin: 0 0 12px;
            }

            .btn {
                display: block;
                width: fit-content;
                font-size: 12px;
                color: var(--color-neutral-lightest);
                border-radius: 20px;
                border: 1px solid var(--color-neutral-lightest);
                padding: 5px 15px;
                transition: color 0.3s linear, border-color 0.3s linear;
                max-width: 100%;
                text-align: center;

                &:hover {
                    color: var(--color-accent-gold);
                    border-color: var(--color-accent-gold);
                }
            }
        }
    }

    .grid-card-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 14px;

        .grid-card {
            &:first-child {
                grid-column: 1/3;
            }

            &:nth-child(2) {
                grid-column: 1/2;
            }

            &:nth-child(3) {
                grid-column: 2/3;
            }

            &:nth-child(4) {
                grid-column: 1/3;
            }

            &:nth-child(5) {
                grid-column: 1/2;
            }

            &:nth-child(6) {
                grid-column: 2/3;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .what-excites-grid-section {
        padding: 50px 0;

        .title-block {
            margin-bottom: 60px;
    
            .title {
                font-size: 29px;
                margin-bottom: 10px;
            }
    
            .title-comment {
                font-size: 22px;
                width: fit-content;
                padding-right: 0;
                transform: rotate(-1deg);
    
                .icon-arrow {
                    width: 130px;
                    height: 129px;
                    right: -136px;
                    top: -24px;
                }
            }
        }

        .grid-card-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 37% 20% 37%;
            height: 500px;
    
            .grid-card {
                &:first-child {
                    grid-column: 1 / 3;
                    grid-row: 1 / 3;
                }
    
                &:nth-child(2) {
                    grid-column: 1 / 2;
                    grid-row: 3;
                }
    
                &:nth-child(3) {
                    grid-column: 2/3;
                    grid-row: 3;
                }
    
                &:nth-child(4) {
                    grid-column: 3;
                    grid-row: 1 / 4;
                }
    
                &:nth-child(5) {
                    grid-column: 4;
                    grid-row: 1 / 2;
                }
    
                &:nth-child(6) {
                    grid-column: 4;
                    grid-row: 2/4;
                }
            }
        }

        .grid-card {
            .card-img {
                max-height: none;
            }

            .card-text {
                height: 50px;
            }

            .card-description {
                font-size: 16px;

                .btn {
                    font-size: 15px;
                    border: 1.5px solid var(--color-neutral-lightest);
                }
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .what-excites-grid-section {
        .grid-card-container {
            grid-template-rows: 37% 14% 41%;
            grid-template-columns: 21% 21% 26% 26%;
            height: 773px;
            grid-gap: 26px;
        }

        .grid-card {
            &:nth-child(2),
            &:nth-child(3) {
                .card-description {
                    font-size: 20px;
                    padding: 30px;
        
                    .btn {
                        font-size: 20px;
                        min-width: 234px;
                    }
        
                    p {
                        margin: 0 0 20px;
                    }
                }
            }

            .card-img {
                max-height: none;
            }

            .card-text {
                height: 78px;
                font-size: 25px;
            }

            .card-description {
                font-size: 20px;
                padding: 30px;

                .btn {
                    font-size: 20px;
                    padding: 12px 20px;
                    min-width: 290px;
                    border-radius: 40px;
                }

                p {
                    margin: 0 0 30px;
                }
            }
        }
    }
}