@charset "UTF-8";
@import '../../../../../library/swiper/swiper-bundle.min.css';
.home-page-hero-img {
  position: relative;
  background: transparent;
  padding-right: 0;
}
.home-page-hero-img .logo-img-container {
  position: relative;
  padding-top: 90px;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding-left: 30px;
}
.home-page-hero-img .logo-img-container .video-block {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-page-hero-img .logo-img-container .video-block .video-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-page-hero-img .logo-img-container .video-block .video-box .play-button {
  background: transparent;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  padding: 0;
}
.home-page-hero-img .logo-img-container .video-block .video-box .play-button i {
  display: block;
  width: 100%;
  height: 100%;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#play-button") no-repeat center;
  background-color: var(--color-neutral-lightest);
  mask-size: cover !important;
}
.home-page-hero-img .logo-img-container .logo-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.home-page-hero-img .logo-img-container .mobile-block {
  display: block;
}
.home-page-hero-img .logo-img-container .desktop-block {
  display: none;
}
.home-page-hero-img .animation-img {
  position: absolute;
  top: 15px;
  right: 40px;
  background: var(--color-primary-light);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#buildings") no-repeat center;
  mask-size: cover !important;
  width: 100px;
  height: 100px;
  z-index: 1;
  animation: maskChange 16s infinite steps(1);
}
@keyframes maskChange {
  0%, 12.5% {
    mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#buildings") no-repeat center;
  }
  12.5%, 25% {
    mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#ramen") no-repeat center;
  }
  25%, 37.5% {
    mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#bikes") no-repeat center;
  }
  37.5%, 50% {
    mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#door") no-repeat center;
  }
  50%, 62.5% {
    mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#kimono") no-repeat center;
  }
  62.5%, 75% {
    mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#tower") no-repeat center;
  }
  75%, 87.5% {
    mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#church") no-repeat center;
  }
  87.5%, 100% {
    mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#lamps") no-repeat center;
  }
}
.home-page-hero-img .title {
  position: relative;
  padding-bottom: 10px;
  padding-right: 40px;
  margin: -60px 0 0 -30px;
}
.home-page-hero-img .title h1, .home-page-hero-img .title h2 {
  background: var(--color-neutral-lightest);
  background-clip: text;
  color: var(--color-text);
  word-wrap: break-word;
}
.home-page-hero-img .title h1 {
  font-size: 35px;
  -webkit-text-stroke: 12px transparent;
  margin-bottom: 0;
  padding: 5px 0;
}
.home-page-hero-img .title h2 {
  font-size: 18px;
  -webkit-text-stroke: 7px transparent;
  font-family: "Poppins-Medium";
  padding: 5px 0;
}

@media screen and (min-width: 768px) {
  .home-page-hero-img {
    padding: 0 15px;
  }
  .home-page-hero-img .logo-img-container {
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-page-hero-img .logo-img-container .logo-img {
    border-radius: 100px;
    max-height: 500px;
    max-width: 1020px;
  }
  .home-page-hero-img .logo-img-container .video-block .video-box .play-button {
    width: 80px;
    height: 75px;
  }
  .home-page-hero-img .animation-img {
    position: absolute;
    top: 25px;
    right: 40px;
  }
}
@media screen and (min-width: 768px) and (min-width: 1200px) {
  .home-page-hero-img .animation-img {
    width: 250px;
    height: 250px;
    right: 0;
  }
}
@media screen and (min-width: 768px) {
  .home-page-hero-img .title {
    position: absolute;
    left: 0;
    margin: 0;
    max-width: 600px;
  }
  .home-page-hero-img .title h1 {
    font-size: 50px;
  }
  .home-page-hero-img .title h2 {
    font-size: 25px;
  }
}
.popular-destination-container {
  padding: 0 !important;
}
.popular-destination-container .title-popular-destinations {
  font-family: Poppins-Bold, sans-serif;
  text-align: center;
}
.popular-destination-container .grid-wrapper.desktop-section {
  display: none;
}
.popular-destination-container .grid-wrapper .country-list {
  margin-top: 25px;
  margin-bottom: 25px;
}
.popular-destination-container .grid-wrapper .country-list .cover {
  margin: 0 auto 15px;
  align-items: center;
  overflow: hidden;
  max-width: 450px;
}
.popular-destination-container .grid-wrapper .country-list .cover .country-item {
  width: 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.popular-destination-container .grid-wrapper .country-list .cover .country-item .subst {
  position: absolute;
  z-index: 3;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -60%);
  border: none;
}
.popular-destination-container .grid-wrapper .country-list .cover .country-item .img-content {
  width: 100%;
  height: 100%;
}
.popular-destination-container .grid-wrapper .country-list .cover .country-item .img-content img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.popular-destination-container .grid-wrapper .country-list .cover .country-item .name-city {
  margin: 0;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  align-items: center;
  text-align: center;
  z-index: 1;
  color: var(--color-neutral-lightest);
  font-family: Poppins-SemiBold, sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}
.popular-destination-container .grid-wrapper .country-list .cover .country-item .country-item-label {
  display: block;
  font: 300 18px "Poppins-Light", sans-serif;
  text-align: center;
  padding: 5px 15px;
}
.popular-destination-container .grid-wrapper .country-list .cover .country-item .country-item-label.d-none {
  display: none;
}
.popular-destination-container .grid-wrapper .btn-load-more {
  display: block;
  width: fit-content;
  font: 700 20px "Poppins-Bold", sans-serif;
  color: var(--color-accent-gold);
  margin: 0 auto;
  background: transparent;
  border: none;
}
.popular-destination-container .button-area {
  width: 185px;
  height: 40px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
}
.popular-destination-container .button-area .a-btn {
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.push-popup-container {
  position: fixed;
  border-radius: 10px;
  top: auto;
  bottom: 0;
  left: 0;
  position: fixed;
  flex-direction: column;
  width: 100%;
  padding: 15px 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(25px);
  box-shadow: 0 -5px 13px 0 rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;
  height: 25%;
  z-index: 99999;
}
.push-popup-container .popup-header {
  margin-bottom: 0;
  height: 25%;
  display: flex;
  align-items: center;
}
.push-popup-container .popup-header span {
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
}
.push-popup-container .popup-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 75%;
  width: 100%;
}
.push-popup-container .popup-buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 5px 30px;
  transition: 0.3s;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 16px;
}
.push-popup-container .popup-buttons button.reject-button {
  border: none;
  color: var(--color-primary);
  background-color: transparent;
  text-decoration: underline;
}
.push-popup-container .popup-buttons button.reject-button:active, .push-popup-container .popup-buttons button.reject-button:hover, .push-popup-container .popup-buttons button.reject-button:focus {
  border-color: var(--yellow-basic);
  color: var(--yellow-basic);
}
.push-popup-container .popup-buttons button.allow-button {
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
  background-color: var(--color-neutral-lightes);
}
.push-popup-container .popup-buttons button.allow-button:active, .push-popup-container .popup-buttons button.allow-button:hover, .push-popup-container .popup-buttons button.allow-button:focus {
  color: var(--color-neutral-lightes);
  background-color: var(--color-primary);
}

@media screen and (min-width: 768px) {
  .push-popup-container {
    position: fixed;
    top: 0;
    left: 6.5%;
    width: auto;
    background-color: var(--color-neutral-lightes);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 99999;
    height: 120px;
  }
  .push-popup-container .popup-header {
    display: flex;
    align-items: center;
    margin: 0;
  }
  .push-popup-container .popup-header .bell-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .push-popup-container .popup-header span {
    font-size: 14px;
  }
  .push-popup-container .popup-buttons {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
.experience-carousel {
  margin: 0 !important;
}
.experience-carousel .slick-arrow {
  display: none !important;
}
.experience-carousel .experience-slider {
  margin-bottom: 0 !important;
  margin-top: 25px !important;
}
.experience-carousel .slider-item {
  width: 300px !important;
  height: auto !important;
  display: flex;
  flex-direction: column;
}
.experience-carousel .slider-item .img-content {
  flex: 1;
}
.experience-carousel .slider-item .text-content {
  flex: 1;
}
@media screen and (min-width: 992px) {
  .experience-carousel .slick-track {
    width: 100% !important;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }
  .experience-carousel .slider-item {
    width: calc(33.3333333333% - 20px) !important;
  }
}

.search-tool {
  background-color: var(--color-neutral-lightest);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 15px;
  margin: 0;
  width: 100%;
  height: 100%;
}
.search-tool .search-section-container {
  display: flex;
  flex-wrap: wrap;
  min-width: 285px;
  margin-top: 15px;
}
.search-tool .search-section-container .location-area, .search-tool .search-section-container .people-area, .search-tool .search-section-container .button-search-area {
  display: flex;
  width: 100%;
  height: 60px;
  flex: 0 1 100%;
  margin: 0 25px 10px 25px;
}
.search-tool .search-section-container .location-area {
  position: relative;
  padding: 0 1px 0 33px;
  align-items: center;
}
.search-tool .search-section-container .location-area i {
  content: "";
  position: absolute;
  top: 15px;
  left: 13px;
  display: inline-block;
  width: 45px;
  height: 28px;
  z-index: 3;
}
@media screen and (min-width: 768px) {
  .search-tool .search-section-container .location-area i {
    left: -15px;
  }
}
.search-tool .search-section-container .location-area .location-icon {
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/search-form-sprite-b7688fe7ca.svg#icon-search_yellow") no-repeat;
  background-size: contain;
}
.search-tool .search-section-container .location-area input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 100%;
  border: 0;
  color: var(--color-primary);
  background-color: transparent;
  font-family: Poppins, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 31px;
  padding-left: 18px;
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 768px) {
  .search-tool .search-section-container .location-area input {
    padding-left: 36px;
    font-size: 14px;
  }
}
.search-tool .search-section-container .location-area input::placeholder {
  color: var(--color-primary);
  text-align: center;
  font-size: 16px;
}
.search-tool .search-section-container .location-area .input-wrapper {
  position: relative;
  width: 100%;
}
.search-tool .search-section-container .location-area .input-wrapper .top-placeholder,
.search-tool .search-section-container .location-area .input-wrapper .bottom-placeholder {
  position: absolute;
  left: 30px;
  color: var(--color-primary);
}
.search-tool .search-section-container .location-area .input-wrapper .top-placeholder {
  top: 5px;
  font-family: Poppins-Bold, sans-serif;
  font-size: 10px;
}
.search-tool .search-section-container .location-area .input-wrapper .bottom-placeholder {
  top: 18px;
  font-family: Poppins-Medium, sans-serif;
  font-size: 8px;
}
.search-tool .search-section-container .people-area {
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  justify-content: center;
}
.search-tool .search-section-container .people-area .people-area-text {
  font-family: Poppins, sans-serif;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.search-tool .search-section-container .people-area .arrow-down-yellow {
  position: absolute;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/search-form-sprite-b7688fe7ca.svg#icon-arrow-down") no-repeat;
  mask-size: contain;
  background: var(--color-accent-gold);
  width: 25px;
  height: 25px;
  right: 5px;
  top: 13px;
  cursor: pointer;
}
.search-tool .search-section-container .people-area .arrow-down-yellow:hover {
  background: var(--color-primary);
}
.search-tool .search-section-container .people-area .people-area-dropdown {
  position: absolute;
  display: flex;
  bottom: -141px;
  background-color: var(--white);
  width: 100%;
  flex-direction: column;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  padding: 10px 0;
  z-index: 999;
  border-radius: 25px;
  padding: 10px 25px 30px;
  height: fit-content;
  min-width: 400px;
}
@media screen and (min-width: 1024px) {
  .search-tool .search-section-container .people-area .people-area-dropdown {
    min-width: 530px;
  }
}
.search-tool .search-section-container .people-area .people-area-dropdown .people-item {
  border-bottom: 1px solid var(--color-neutral-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-primary);
  padding: 15px 0;
}
.search-tool .search-section-container .people-area .people-area-dropdown .people-item .people-title {
  font-family: Poppins-SemiBold, sans-serif;
  font-size: 15px;
}
.search-tool .search-section-container .people-area .people-area-dropdown .people-item .people-title-additional {
  font-family: Poppins-Regular, sans-serif;
  color: var(--color-primary-light);
}
.search-tool .search-section-container .people-area .people-area-dropdown .people-item .amount-people {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Poppins-Medium, sans-serif;
  font-size: 16px;
}
.search-tool .search-section-container .people-area .people-area-dropdown .people-item button {
  width: 20px;
  height: 20px;
  border: none;
  background-size: contain;
  border: 2px solid var(--color-accent-gold);
  color: var(--color-accent-gold);
  background: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 1;
}
.search-tool .search-section-container .people-area .people-area-dropdown .people-item button:disabled {
  color: var(--color-neutral-light);
  border-color: var(--color-neutral-light);
}
.search-tool .search-section-container .button-search-area button {
  width: 100%;
  border: 1px solid var(--color-accent-gold);
  background-color: var(--color-accent-gold);
  color: var(--color-primary);
  letter-spacing: 0.75px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: Poppins-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
}
.search-tool .search-section-container .button-search-area button:hover, .search-tool .search-section-container .button-search-area button:focus, .search-tool .search-section-container .button-search-area button:active {
  border: 1px solid var(--color-accent-gold);
  background-color: var(--color-primary);
  color: var(--color-accent-gold);
}
.search-tool .search-section-container .button-search-area .book {
  display: none;
}
.search-tool .search-section-container .button-search-area .find {
  display: none;
}
.search-tool .search-section-container .search-results {
  display: flex;
  position: absolute;
  width: 40%;
  max-height: 160px;
  border: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: var(--white);
  overflow: auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  z-index: 3;
  margin: 60px 2px 0 26px;
}
.search-tool .search-section-container .search-results__list {
  width: 100%;
}
.search-tool .search-section-container .search-results__list-item {
  text-align: start;
  position: relative;
  transition: 0.5s;
}
.search-tool .search-section-container .search-results__list-item:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 23px;
  display: block;
  width: 21px;
  height: 13px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/search-form-sprite-b7688fe7ca.svg#icon-marker_yellow") no-repeat;
  mask-size: contain;
  background: var(--color-accent-gold);
}
@media screen and (min-width: 768px) {
  .search-tool .search-section-container .search-results__list-item:before {
    left: 24px;
  }
}
.search-tool .search-section-container .search-results__list-item a {
  display: block;
  padding: 10px 0 5px 48px;
  font-family: Poppins, sans-serif;
  color: var(--color-primary);
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .search-tool .search-section-container .search-results__list-item a {
    padding: 10px 0 5px 60px;
  }
}
.search-tool .search-section-container .search-results__list-item a:focus, .search-tool .search-section-container .search-results__list-item a:hover, .search-tool .search-section-container .search-results__list-item a:active {
  background-color: var(--color-primary);
  color: var(--white);
}
.search-tool .search-section-container .search-results__list-item.selected, .search-tool .search-section-container .search-results__list-item:focus, .search-tool .search-section-container .search-results__list-item:hover, .search-tool .search-section-container .search-results__list-item:active {
  background-color: var(--color-primary);
}
.search-tool .search-section-container .search-results__list-item.selected a, .search-tool .search-section-container .search-results__list-item:focus a, .search-tool .search-section-container .search-results__list-item:hover a, .search-tool .search-section-container .search-results__list-item:active a {
  color: var(--white);
}
.search-tool .search-section-container .search-results__list-item:hover {
  background-color: var(--color-primary);
}
.search-tool .search-section-container .search-results__list-item:hover a {
  color: var(--white);
}
.search-tool .search-section-container .search-results .not-found {
  font-family: Poppins, sans-serif;
  padding: 5px 0 5px 55px;
  color: var(--color-primary);
  font-size: 9px;
}

@media screen and (min-width: 768px) {
  .search-tool {
    background-color: var(--color-neutral-lightest);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 0;
    align-items: center;
    width: 550px;
    margin: 0 auto;
  }
  .search-tool .search-section-container {
    flex-wrap: nowrap;
    min-width: initial;
    width: 100%;
    margin-top: 0;
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) and (min-width: 992px) {
  .search-tool .search-section-container {
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) {
  .search-tool .search-section-container .location-area, .search-tool .search-section-container .people-area, .search-tool .search-section-container .button-search-area {
    height: 50px;
    margin: 10px;
    border: none;
  }
  .search-tool .search-section-container .location-area .area-title, .search-tool .search-section-container .people-area .area-title, .search-tool .search-section-container .button-search-area .area-title {
    display: flex;
    position: absolute;
    background-color: var(--white);
    padding: 0 5px;
    top: -18px;
    font-family: Poppins-Light, sans-serif;
    font-size: 14px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
  }
  .search-tool .search-section-container .location-area .area-title .location-icon, .search-tool .search-section-container .people-area .area-title .location-icon, .search-tool .search-section-container .button-search-area .area-title .location-icon {
    position: initial;
    width: 20px;
    height: 20px;
  }
  .search-tool .search-section-container .location-area .area-title span, .search-tool .search-section-container .people-area .area-title span, .search-tool .search-section-container .button-search-area .area-title span {
    padding: 0 5px;
  }
  .search-tool .search-section-container .people-area .people-area-dropdown {
    bottom: -141px;
    flex-direction: column;
    padding: 10px 30px 30px;
  }
  .search-tool .search-section-container .people-area .people-area-dropdown .people-item .people-title, .search-tool .search-section-container .people-area .people-area-dropdown .people-item .amount-people {
    font-size: 17px;
  }
  .search-tool .search-section-container .people-area .people-area-dropdown .people-item button {
    width: 25px;
    height: 25px;
  }
  .search-tool .search-section-container .location-area {
    position: relative;
    flex: 0 1 66%;
    padding: 0 1px 0 15px;
  }
  .search-tool .search-section-container .location-area i {
    content: "";
    position: absolute;
    top: 10px;
    left: 16px;
    width: 55px;
    height: 28px;
  }
  .search-tool .search-section-container .location-area input {
    font-size: 14px;
    line-height: 28px;
    padding-left: 45px;
  }
  .search-tool .search-section-container .location-area input::placeholder {
    color: var(--color-primary);
    text-align: left;
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) and (min-width: 992px) {
  .search-tool .search-section-container .location-area input {
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) {
  .search-tool .search-section-container .location-area .input-wrapper .top-placeholder,
  .search-tool .search-section-container .location-area .input-wrapper .bottom-placeholder {
    left: 51px;
  }
  .search-tool .search-section-container .location-area .input-wrapper .top-placeholder.top-placeholder,
  .search-tool .search-section-container .location-area .input-wrapper .bottom-placeholder.top-placeholder {
    top: 0;
    font-size: 15px;
  }
  .search-tool .search-section-container .location-area .input-wrapper .top-placeholder.bottom-placeholder,
  .search-tool .search-section-container .location-area .input-wrapper .bottom-placeholder.bottom-placeholder {
    top: 18px;
    font-size: 12px;
  }
  .search-tool .search-section-container .button-search-area {
    flex: 0 1 34%;
    letter-spacing: 1.05px;
    margin-right: 20px;
  }
  .search-tool .search-section-container .button-search-area button {
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) and (min-width: 992px) {
  .search-tool .search-section-container .button-search-area button {
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) {
  .search-tool .search-section-container .search-results {
    position: absolute;
    max-height: 320px;
    border: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: var(--white);
    overflow: auto;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    margin: 69px 0 0 0;
  }
  .search-tool .search-section-container .search-results__list-item:before {
    width: 47px;
    left: 36px;
  }
  .search-tool .search-section-container .search-results__list-item a {
    padding: 10px 0 5px 65px;
  }
  .search-tool .search-section-container .search-results .not-found {
    font-size: 15px;
  }
}
@media screen and (min-width: 992px) {
  .search-tool {
    width: 600px;
  }
  .search-tool .search-section-container {
    font-size: 16px;
  }
  .search-tool .search-section-container .button-search-area button {
    font-size: 16px;
  }
}
@media screen and (min-width: 1200px) {
  .search-tool {
    top: 395px;
    height: 80px;
  }
  .search-tool .search-section-container {
    max-width: 1080px;
  }
  .search-tool .search-section-container .location-area, .search-tool .search-section-container .people-area, .search-tool .search-section-container .button-search-area {
    height: 60px;
  }
  .search-tool .search-section-container .location-area i {
    top: 13px;
    left: 28px;
    width: 65px;
    height: 30px;
  }
  .search-tool .search-section-container .location-area input {
    padding-left: 60px;
    font-size: 16px;
  }
  .search-tool .search-section-container .location-area input::placeholder {
    font-size: 16px;
  }
  .search-tool .search-section-container .button-search-area button {
    font-size: 16px;
  }
}
.search-tool {
  padding: 30px 15px;
  height: auto;
  width: auto;
}
.search-tool .search-section-container {
  background: var(--color-supportive);
  border-radius: 19px;
  flex-wrap: nowrap;
  max-width: 925px;
  max-height: 72px;
  margin: 0px auto;
  box-shadow: 0px 3.6px 3.6px rgba(0, 0, 0, 0.25);
}
.search-tool .search-section-container.scrolling {
  position: fixed;
  top: calc(var(--nav-bar-height) + 10px);
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
}
.search-tool .search-section-container.scale-animation {
  animation: scaleAnimation 2s ease-in-out forwards;
}
@keyframes scaleAnimation {
  0% {
    transform: translateX(-50%) scale(1);
  }
  50% {
    transform: translateX(-50%) scale(1.2);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
}
.search-tool .search-section-container .location-area {
  height: auto;
  margin: 0;
  flex: none;
  width: 80%;
  padding: 0;
  z-index: 1;
}
.search-tool .search-section-container .location-area .location-icon {
  position: relative;
  top: auto;
  left: auto;
  display: none;
}
.search-tool .search-section-container .location-area input {
  font-size: 13px;
  color: var(--color-text);
  padding: 3px 0 3px 10px;
  background: var(--color-supportive);
  border-radius: 19px 0 0 19px;
}
.search-tool .search-section-container .location-area input::placeholder {
  font-size: 13px;
  padding: 0;
  text-align: left;
}
.search-tool .search-section-container .button-search-area {
  height: auto;
  margin: 0;
  flex: none;
  width: 20%;
}
.search-tool .search-section-container .button-search-area button {
  font-size: 11px;
  background: var(--color-primary);
  color: var(--color-neutral-lightest);
  border-top-right-radius: 19px;
  border-bottom-right-radius: 19px;
  border: none;
}
.search-tool .search-section-container .button-search-area button:active, .search-tool .search-section-container .button-search-area button:hover {
  border: none;
  background: var(--color-accent-gold);
  color: var(--color-primary);
}
.search-tool .search-section-container .search-results {
  width: 100%;
  top: 55%;
  left: 0;
  margin: 0;
  z-index: -1;
  border-radius: 0 0 19px 19px;
}
.search-tool .search-section-container .search-results__list {
  background: var(--color-supportive);
}
.search-tool .search-section-container .search-results__list-item:first-child a {
  padding-top: 30px;
}
.search-tool .search-section-container .search-results__list-item:first-child::before {
  top: 30px;
}
.search-tool .search-section-container .search-results__list-item a {
  padding: 13px 10px 13px 50px;
}
.search-tool .search-section-container .search-results__list-item::before {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/search-form-sprite-b7688fe7ca.svg#location") no-repeat;
  mask-size: contain;
  width: 20px;
  height: 22px;
  top: 14px;
  left: 19px;
  background: var(--color-primary);
}
.search-tool .search-section-container .search-results__list-item.selected::before, .search-tool .search-section-container .search-results__list-item:focus::before, .search-tool .search-section-container .search-results__list-item:hover::before, .search-tool .search-section-container .search-results__list-item:active::before {
  background: var(--color-accent-gold);
}

@media screen and (min-width: 768px) {
  .search-tool {
    padding: 50px 15px;
  }
  .search-tool .search-section-container {
    border-radius: 35px;
    font-size: 22px;
  }
  .search-tool .search-section-container .location-area input {
    font-size: 22px;
    padding: 16px 0 16px 25px;
    border-radius: 35px 0 0 35px;
  }
  .search-tool .search-section-container .location-area input::placeholder {
    font-size: 22px;
  }
  .search-tool .search-section-container .search-results {
    border-radius: 0 0 35px 35px;
  }
  .search-tool .search-section-container .search-results__list-item:first-child a {
    padding-top: 50px;
  }
  .search-tool .search-section-container .search-results__list-item:first-child::before {
    top: 53px;
  }
  .search-tool .search-section-container .search-results__list-item::before {
    width: 24px;
    height: 29px;
    top: 20px;
  }
  .search-tool .search-section-container .search-results__list-item a {
    padding: 18px 10px 18px 50px;
  }
  .search-tool .search-section-container .button-search-area {
    width: 20%;
  }
  .search-tool .search-section-container .button-search-area button {
    font-size: 22px;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
  }
}
.section-authentic-experiences {
  background-color: var(--color-primary);
  color: var(--color-neutral-lightest);
  padding: 30px 0;
  text-align: center;
  font: 400 17px "Poppins-Medium", sans-serif;
  line-height: 1.5;
}
.section-authentic-experiences .title-block {
  margin-bottom: 50px;
}
.section-authentic-experiences .title {
  color: var(--color-accent-gold);
}
.section-authentic-experiences .authentic-experiences-list .list-item {
  margin-bottom: 30px;
}
.section-authentic-experiences .card-authentic-experiences {
  display: flex;
  flex-direction: column;
  align-items: center;
  font: 400 21px "Poppins-Medium", sans-serif;
}
.section-authentic-experiences .card-authentic-experiences .card-img {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  overflow: hidden;
  padding: 11px;
  margin-bottom: 15px;
  flex-shrink: 0;
  background: var(--color-neutral-lightest);
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-authentic-experiences .card-authentic-experiences .card-img .icon {
  display: block;
  width: 66px;
  height: 66px;
}
.section-authentic-experiences .card-authentic-experiences .card-img .icon-food {
  background: var(--color-primary);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#icon-food") center no-repeat;
  mask-size: 300% auto;
}
.section-authentic-experiences .card-authentic-experiences .card-img .icon-discover {
  background: var(--color-primary);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#icon-discover") center no-repeat;
  mask-size: 300% auto;
}
.section-authentic-experiences .card-authentic-experiences p:last-child {
  margin: 0;
}

@media screen and (min-width: 768px) {
  .section-authentic-experiences {
    text-align: left;
    padding: 75px 0;
  }
  .section-authentic-experiences .content-block {
    display: flex;
    justify-content: space-between;
    gap: 60px;
  }
  .section-authentic-experiences .title-block {
    width: 55%;
  }
  .section-authentic-experiences .authentic-experiences-list {
    width: 45%;
  }
  .section-authentic-experiences .authentic-experiences-list .list-item {
    margin-bottom: 50px;
  }
  .section-authentic-experiences .card-authentic-experiences {
    flex-direction: row;
  }
  .section-authentic-experiences .card-authentic-experiences .card-img {
    margin: 0 20px 0 0;
  }
}
@media screen and (min-width: 992px) {
  .section-authentic-experiences .title {
    font-size: 44px;
  }
  .section-authentic-experiences .content-block {
    gap: 95px;
  }
  .section-authentic-experiences .card-authentic-experiences {
    font-size: 24px;
  }
  .section-authentic-experiences .card-authentic-experiences .card-img .icon {
    width: 91px;
    height: 91px;
    padding: 15px;
  }
}
.section-as-seen-in {
  padding: 32px 0 46px;
}
.section-as-seen-in .title-block {
  text-align: center;
}
.section-as-seen-in .list-publications {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.section-as-seen-in .list-publications .list-item {
  width: calc(50% - 26px);
  display: flex;
  align-items: center;
  height: 120px;
  overflow: hidden;
}
.section-as-seen-in .list-publications .list-item img {
  width: 100%;
  height: auto;
}
.section-as-seen-in .list-publications .the-guardian-logo {
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#the-guardian-logo") center no-repeat;
  background-size: 50% !important;
}
.section-as-seen-in .list-publications .national-geographic-logo {
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#national-geographic-logo") center no-repeat;
  background-size: 140% !important;
}
.section-as-seen-in .list-publications .the-times-logo {
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#the-times-logo") center no-repeat;
  background-size: 70% !important;
}
.section-as-seen-in .list-publications .vg-tv-logo {
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#vg-tv-logo") center no-repeat;
  background-size: 60% !important;
}

@media screen and (min-width: 580px) {
  .section-as-seen-in .list-publications .list-item {
    height: 150px;
  }
}
@media screen and (min-width: 768px) {
  .section-as-seen-in {
    padding: 55px 0 90px;
  }
  .section-as-seen-in .title-block .title {
    margin-bottom: 65px;
  }
  .section-as-seen-in .list-publications {
    gap: 35px;
  }
  .section-as-seen-in .list-publications .list-item {
    width: calc(25% - 35px);
  }
  .section-as-seen-in .list-publications .the-guardian-logo {
    background-size: contain !important;
  }
  .section-as-seen-in .list-publications .the-times-logo {
    background-size: contain !important;
  }
  .section-as-seen-in .list-publications .vg-tv-logo {
    background-size: contain !important;
  }
}
.section-customer-reviews {
  background-color: var(--blue-basic);
  color: var(--white);
  font: 400 13px "Poppins-Regular", sans-serif;
  padding: 30px 0;
  margin-bottom: 155px;
}
.section-customer-reviews .title-box {
  text-align: center;
}
.section-customer-reviews .title-box .title {
  color: var(--white);
  margin-bottom: 4px;
}
.section-customer-reviews .icon {
  display: block;
  background-color: var(--white);
  mask-size: cover !important;
  flex-shrink: 0;
}
.section-customer-reviews .icon-tripadvisor-rating {
  width: 93px;
  height: 18px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/customer-reviews-section-sprite-3aec472268.svg#icon-tripadvisor-rating2") no-repeat center;
}
.section-customer-reviews .icon-google-rating {
  width: 94px;
  height: 18px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/customer-reviews-section-sprite-3aec472268.svg#icon-google-rating") no-repeat center;
}
.section-customer-reviews .icon-google {
  width: 27px;
  height: 27px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/customer-reviews-section-sprite-3aec472268.svg#icon-google") no-repeat center;
}
.section-customer-reviews .icon-tripadvisor {
  width: 36px;
  height: 25px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/customer-reviews-section-sprite-3aec472268.svg#icon-tripadvisor") no-repeat center;
}
.section-customer-reviews .customer-reviews-block {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
  font-size: 9px;
}
.section-customer-reviews .customer-reviews-block .customer-reviews-item {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  max-width: 160px;
}
.section-customer-reviews .customer-reviews-block .customer-reviews-item .statistic-block .icon {
  margin-bottom: 5px;
}
.section-customer-reviews .autentic-stamp-block {
  margin: 20px 0 -60px;
  position: relative;
  display: flex;
  justify-content: center;
}
.section-customer-reviews .autentic-stamp-block .icon-stamp {
  width: 90px;
  height: 90px;
  display: block;
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/customer-reviews-section-sprite-3aec472268.svg#authentic-stamp") center no-repeat;
  background-size: cover;
}
.section-customer-reviews .autentic-stamp-block .stamp-description {
  position: absolute;
  bottom: -100px;
  color: var(--color-text);
  font: 500 15px Architects-Daughter, sans-serif;
  max-width: 270px;
  text-align: center;
  margin-left: 90px;
}
.section-customer-reviews .autentic-stamp-block .stamp-description:before {
  content: "";
  display: block;
  width: 60px;
  height: 20px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/customer-reviews-section-sprite-3aec472268.svg#arrow3") no-repeat center;
  background-color: var(--color-text);
  mask-size: cover !important;
  transform: rotate(90deg);
  position: absolute;
  top: -44px;
  left: 5px;
}

@media screen and (min-width: 1024px) {
  .section-customer-reviews {
    font-size: 18px;
    padding: 50px 0 50px;
    margin-bottom: 115px;
  }
  .section-customer-reviews .icon-tripadvisor-rating,
  .section-customer-reviews .icon-google-rating {
    width: 180px;
    height: 34px;
  }
  .section-customer-reviews .icon-tripadvisor {
    width: 90px;
    height: 60px;
  }
  .section-customer-reviews .icon-google {
    width: 60px;
    height: 60px;
  }
  .section-customer-reviews .title-box {
    margin-bottom: 40px;
  }
  .section-customer-reviews .customer-reviews-block {
    font-size: 18px;
  }
  .section-customer-reviews .customer-reviews-block .customer-reviews-item {
    gap: 15px;
    max-width: 360px;
  }
  .section-customer-reviews .autentic-stamp-block {
    margin-bottom: -110px;
  }
  .section-customer-reviews .autentic-stamp-block .icon-stamp {
    width: 150px;
    height: 150px;
  }
  .section-customer-reviews .autentic-stamp-block .stamp-description {
    left: 50%;
    bottom: -50px;
    margin-left: 130px;
    font-size: 17px;
  }
  .section-customer-reviews .autentic-stamp-block .stamp-description:before {
    width: 100px;
    height: 35px;
    top: 15px;
    left: -105px;
    transform: rotate(0);
  }
}
.section-featured-experiences {
  padding: 30px 0;
}
.section-featured-experiences .title {
  text-align: center;
  padding: 0 55px;
}
.section-featured-experiences .subtitle {
  text-align: center;
  display: none;
  margin-bottom: 30px;
}
.section-featured-experiences .swiper {
  width: 100%;
  padding: 10px 0;
}
.section-featured-experiences .experience-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--color-neutral-lighter);
  width: 80%;
  max-width: 310px;
  min-height: 230px;
  border-radius: 10px;
  box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.25);
  position: relative;
}
.section-featured-experiences .experience-card a {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.section-featured-experiences .experience-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-height: 116px;
  width: 100%;
  object-fit: cover;
}
.section-featured-experiences .experience-card .card-info {
  padding: 0 15px;
  display: flex;
  gap: 15px;
  position: relative;
  width: 100%;
  flex: auto;
}
.section-featured-experiences .experience-card .card-info .city {
  position: absolute;
  right: -10%;
  top: -55%;
  color: var(--color-primary);
  background: var(--color-accent-gold);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/experience-card-sprite-e5197cd7fb.svg#city-background") no-repeat center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 85px;
  height: 85px;
  font-size: 16px;
  font-family: Architects-Daughter, sans-serif;
}
.section-featured-experiences .experience-card .card-info .experience-title {
  padding: 20px 5px 20px 0;
  margin-bottom: 0;
  width: 80%;
  font-size: 15px;
  word-break: normal;
  word-wrap: normal;
  position: relative;
  font-family: Poppins-SemiBold, sans-serif;
}
.section-featured-experiences .experience-card .card-info .experience-title::after {
  content: "";
  position: absolute;
  right: 0;
  top: 2%;
  width: 6px;
  height: 95%;
  background-color: var(--color-primary);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/experience-card-sprite-e5197cd7fb.svg#dashed-line") no-repeat center;
  mask-size: cover;
}
.section-featured-experiences .experience-card .card-info .info-block {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  font-size: 10px;
  font-family: Poppins, sans-serif;
  width: 30%;
  position: relative;
}
.section-featured-experiences .experience-card .card-info .info-block-row {
  display: flex;
  align-items: center;
  gap: 8px;
}
.section-featured-experiences .experience-card .card-info .info-block .icon {
  display: block;
  width: 15px;
  height: 15px;
}
.section-featured-experiences .experience-card .card-info .info-block .star {
  background: var(--color-accent-gold);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/experience-card-sprite-e5197cd7fb.svg#star-yellow") no-repeat center;
}
.section-featured-experiences .experience-card .card-info .info-block .price-tag {
  background: var(--color-primary);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/experience-card-sprite-e5197cd7fb.svg#price-tag") no-repeat center;
}
.section-featured-experiences .experience-card .card-info .info-block .clock {
  background: var(--color-primary);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/experience-card-sprite-e5197cd7fb.svg#clock") no-repeat center;
}

@media screen and (min-width: 768px) {
  .section-featured-experiences .title {
    font-size: 29px;
    margin-bottom: 20px;
  }
  .section-featured-experiences .subtitle {
    display: block;
    font-size: 25px;
  }
  .section-featured-experiences .experience-card {
    width: 60%;
    max-width: 590px;
    min-height: 365px;
  }
  .section-featured-experiences .experience-card img {
    max-height: 225px;
  }
  .section-featured-experiences .experience-card .card-info .city {
    width: 100px;
    height: 100px;
    font-size: 18px;
  }
  .section-featured-experiences .experience-card .card-info .experience-title {
    font-size: 26px;
  }
  .section-featured-experiences .experience-card .card-info .info-block {
    font-size: 15px;
    padding-left: 3%;
  }
  .section-featured-experiences .experience-card .card-info .info-block .icon {
    width: 20px;
    height: 20px;
  }
}
@media screen and (min-width: 992px) {
  .section-featured-experiences {
    padding: 50px 0;
  }
  .section-featured-experiences .experience-card {
    min-height: 410px;
  }
  .section-featured-experiences .experience-card .card-info .city {
    width: 120px;
    height: 120px;
    font-size: 20px;
  }
  .section-featured-experiences .experience-card .card-info .experience-title {
    font-size: 28px;
  }
  .section-featured-experiences .experience-card .card-info .info-block {
    font-size: 20px;
  }
  .section-featured-experiences .experience-card .card-info .info-block .icon {
    width: 25px;
    height: 25px;
  }
}
.icon {
  width: 20px;
  height: 21px;
  display: inline-block;
  background-color: var(--white);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#bookmark") no-repeat center;
}
@media screen and (min-width: 1440px) {
  .icon {
    width: 25px;
    height: 26px;
  }
}

.icon-knows-every-hidden-gem {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#find-on-map") no-repeat center;
}

.icon-loves-food-local-culture {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#noodle") no-repeat center;
}

.icon-cultural-stories-local-insights {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#bookmark") no-repeat center;
}

.icon-knows-the-city-inside-out {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#direction") no-repeat center;
}

.icon-brings-stories-places-life {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#speech-bubble") no-repeat center;
}

.icon-architectural-cultural-explorer {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#skyline") no-repeat center;
}

.icon-nature-city-explorer {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#building") no-repeat center;
}

.icon-local-historian {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#guide-book") no-repeat center;
}

.icon-eco-friendly-advocate {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#ecology") no-repeat center;
}

.icon-photo-spots-explorer {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#photo-pin") no-repeat center;
}

.icon-art-creative-spaces-explorer {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#map-bulb") no-repeat center;
}

.icon-chevron {
  display: inline-block;
  width: 19px;
  height: 12px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#icon-arrow-down") no-repeat center;
  transition: color 0.3s linear, background-color 0.3s linear;
}

.compass {
  display: inline-block;
  width: 25px;
  height: 25px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#compass") no-repeat center;
}

.noodle {
  display: inline-block;
  width: 15px;
  height: 16px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#noodle") no-repeat center;
}

.icon-binoculars {
  display: inline-block;
  width: 15px;
  height: 13px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#icon-binoculars") no-repeat center;
}

.icon-city {
  width: 15px;
  height: 13px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#icon-city") no-repeat center;
}

.icon-flag {
  display: inline-block;
  width: 15px;
  height: 12px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#icon-flag") no-repeat center;
}

.language {
  display: inline-block;
  width: 25px;
  height: 25px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#language") no-repeat center;
}

.pin-with-hard {
  width: 25px;
  height: 25px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#pin-with-hard") no-repeat center;
}

.host-details-popup {
  overflow: hidden;
  height: 100vh;
  font: 400 15px "Poppins-Regular", sans-serif;
  line-height: 1.4;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 99999;
  transition: left 0.3s linear, background-color 0.5s linear;
  background-color: rgba(0, 0, 0, 0.5);
}
.host-details-popup.hidden-popup {
  left: -100%;
  background-color: transparent;
}
.host-details-popup .popup-wrapper {
  width: 100%;
}
.host-details-popup .popup-content {
  padding: 0 0 20px;
  margin: 0;
  border: 0;
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100vh - 72px);
}
.host-details-popup .popup-header {
  background-color: var(--color-primary);
}
.host-details-popup .btn-back {
  display: flex;
  align-items: center;
  padding: 31px 20px 23px;
  color: var(--white);
  background-color: var(--color-primary);
  font-size: 15px;
  width: fit-content;
  cursor: pointer;
}
.host-details-popup .btn-back:hover {
  color: var(--yellow-basic);
}
.host-details-popup .btn-back:hover .icon-chevron {
  background-color: var(--yellow-basic);
}
.host-details-popup .btn-back .icon-chevron {
  background-color: var(--white);
  transform: rotate(90deg);
  margin-right: 10px;
}
.host-details-popup .gallery-box {
  position: relative;
  overflow-x: hidden;
}
.host-details-popup .gallery-box.container-l {
  padding: 30px 0;
}
.host-details-popup .gallery-box .experience-category {
  position: absolute;
  left: 20px;
  bottom: 22px;
  max-width: 98%;
}
.host-details-popup .gallery-box .experience-category .icon-category {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--white);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--grey-dark);
  display: flex;
  align-items: center;
  justify-content: center;
}
.host-details-popup .gallery-box .experience-category .label-category {
  font-size: 11px;
  color: var(--white);
  background-color: var(--grey-dark);
  border-radius: 20px 10px 10px 20px;
  padding: 5px 10px 4px 45px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.host-details-popup .gallery-box .gallery-list {
  display: flex;
  overflow-x: auto;
}
.host-details-popup .gallery-box .gallery-item {
  width: 250px;
  height: 250px;
  flex-shrink: 0;
  border-radius: 15px;
  overflow: hidden;
  margin-left: 20px;
}
.host-details-popup .gallery-box .gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.host-details-popup .description-box {
  padding: 10px 20px 30px;
  line-height: 1.4;
}
.host-details-popup .description-box .popup-title {
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 800px;
}
.host-details-popup .description-box .popup-title .title {
  font: 400 21px Architects-Daughter, sans-serif;
  margin-bottom: 0;
}
.host-details-popup .description-box .popup-title .description {
  max-width: 800px;
}
.host-details-popup .description-box .additional-information {
  transition: height 0.3s linear visibility 0.3s linear;
  max-width: 800px;
  margin: 0;
}
.host-details-popup .reviews-block {
  display: flex;
  align-items: center;
  gap: 2px;
  font: 400 12px "Poppins-Regular", sans-serif;
}
.host-details-popup .reviews-block .icon-star {
  width: 15px;
  height: 15px;
  display: block;
  background-color: var(--blue-basic);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#star_yellow") no-repeat center;
  mask-size: cover;
}
.host-details-popup .reviews-block .reviews {
  display: block;
  padding: 0;
}
.host-details-popup .reviews-block .reviews-count {
  color: var(--grey-dark);
}
.host-details-popup .skills-box {
  padding: 20px 20px 50px;
  overflow-x: hidden;
}
.host-details-popup .skills-box .skills-slider {
  display: flex;
  gap: 15px;
  overflow-x: auto;
  margin-right: -20px;
  padding: 20px 5px;
}
.host-details-popup .skills-box .skills-slider .list-item .skills-card {
  height: 100%;
}
.host-details-popup .skills-box .icon {
  background-color: var(--blue-basic);
}
.host-details-popup .skills-box .skills-card {
  border-radius: 10px;
  background-color: var(--grey-light-basic);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 25px 20px;
  font-size: 15px;
  width: 250px;
  line-height: 1.6;
}
.host-details-popup .skills-box .skills-card .card-title {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}
.host-details-popup .skills-box .skills-card .explore-list .list-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.host-details-popup .skills-box .skills-card .card-list .list-item {
  padding-left: 15px;
  position: relative;
}
.host-details-popup .skills-box .skills-card .card-list .list-item::before {
  content: "•";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 4px;
}
.host-details-popup .desktop-block {
  display: none !important;
}
.host-details-popup .facts-box {
  padding: 36px 20px;
}
.host-details-popup .facts-box .title {
  margin-bottom: 8px;
  color: var(--white);
}
.host-details-popup .reviews-quote-box {
  padding: 62px 20px 51px;
}
.host-details-popup .reviews-quote-box .card-quote {
  padding: 32px 27px 58px 14px;
  position: relative;
  width: 277px;
  height: 217px;
  z-index: 1;
  font-size: 15px;
  margin: 0 auto;
  text-align: center;
  font-style: italic;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.host-details-popup .reviews-quote-box .card-quote::before, .host-details-popup .reviews-quote-box .card-quote::after {
  content: "";
  display: block;
  width: 65px;
  height: 65px;
  background-color: var(--blue-basic);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#quote") no-repeat center;
  mask-size: cover;
  position: absolute;
  top: -22px;
  left: -13px;
}
.host-details-popup .reviews-quote-box .card-quote::after {
  top: auto;
  left: auto;
  bottom: 8px;
  right: 0;
  transform: rotate(180deg);
}
.host-details-popup .reviews-quote-box .card-quote blockquote {
  margin: 0;
}
.host-details-popup .reviews-quote-box .card-quote .quote-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: -4px;
}
.host-details-popup .reviews-quote-box .card-quote .quote-author {
  font-family: "Poppins-SemiBold";
  font-style: normal;
}
.host-details-popup .author-quote-box {
  padding: 55px 34px 55px 36px;
}
.host-details-popup .reviews-box.container-l {
  padding-top: 30px;
  padding-bottom: 30px;
}
.host-details-popup .reviews-box .reviews-list {
  margin-bottom: 20px;
}
.host-details-popup .reviews-box .reviews-list > .list-item {
  margin-bottom: 13px;
  max-width: 354px;
}
.host-details-popup .reviews-box .card-reviews {
  border: 1px solid var(--grey-dark);
}
.host-details-popup .reviews-box .card-reviews .reviews-info {
  gap: 0;
}

@media screen and (min-width: 1024px) {
  .host-details-popup {
    font-size: 17px;
  }
  .host-details-popup .btn-back {
    font-size: 24px;
  }
  .host-details-popup .btn-back .icon-chevron {
    width: 40px;
    height: 40px;
  }
  .host-details-popup .gallery-box.container-l {
    padding: 30px 0;
  }
  .host-details-popup .gallery-box .gallery-item {
    width: 320px;
    height: 320px;
  }
  .host-details-popup .gallery-box .experience-category {
    position: absolute;
    left: 20px;
    bottom: 22px;
    max-width: 98%;
  }
  .host-details-popup .gallery-box .experience-category .icon-category {
    width: 45px;
    height: 45px;
  }
  .host-details-popup .gallery-box .experience-category .label-category {
    font-size: 14px;
    padding: 6px 10px 5px 50px;
  }
  .host-details-popup .description-box .popup-title {
    gap: 30px;
  }
  .host-details-popup .description-box .popup-title .title {
    font-size: 40px;
  }
  .host-details-popup .reviews-block {
    gap: 5px;
    font-size: 22px;
  }
  .host-details-popup .reviews-block .icon-star {
    width: 24px;
    height: 24px;
  }
  .host-details-popup .skills-box .skills-slider {
    overflow-x: visible;
  }
  .host-details-popup .skills-box .skills-card {
    width: 285px;
    font-size: 18px;
  }
  .host-details-popup .mobile-block {
    display: none !important;
  }
  .host-details-popup .desktop-block {
    display: block !important;
  }
  .host-details-popup .desktop-host-info-block {
    background-color: var(--blue-basic);
    color: var(--white);
  }
  .host-details-popup .desktop-host-info-block .container-l {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    gap: 40px;
  }
  .host-details-popup .desktop-host-info-block .title {
    margin-bottom: 30px;
    text-align: center;
  }
  .host-details-popup .desktop-host-info-block .facts-box {
    padding: 0;
  }
  .host-details-popup .desktop-host-info-block .facts-box .facts-box-tex {
    position: relative;
    padding: 36px 30px 50px;
    width: 271px;
    height: 250px;
    display: flex;
    flex-direction: column;
  }
  .host-details-popup .desktop-host-info-block .facts-box .facts-box-tex p {
    margin: 0;
  }
  .host-details-popup .desktop-host-info-block .facts-box .facts-box-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -4px;
  }
  .host-details-popup .desktop-host-info-block .reviews-quote-box {
    padding: 0;
  }
  .host-details-popup .desktop-host-info-block .reviews-quote-box .card-quote {
    padding: 32px 27px 58px 14px;
    width: 277px;
    height: 217px;
  }
  .host-details-popup .desktop-host-info-block .reviews-quote-box .card-quote::before, .host-details-popup .desktop-host-info-block .reviews-quote-box .card-quote::after {
    background-color: var(--yellow-basic);
  }
  .host-details-popup .desktop-host-info-block .author-quote-box {
    background-color: transparent;
    padding: 0;
  }
  .host-details-popup .desktop-host-info-block .author-quote-box .author-quote-box-text {
    position: relative;
    padding: 30px 30px 65px 35px;
    width: 358px;
    height: 283px;
    display: flex;
    flex-direction: column;
  }
  .host-details-popup .desktop-host-info-block .author-quote-box .author-quote-box-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -4px;
  }
  .host-details-popup .reviews-box.container-l {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .host-details-popup .reviews-box .reviews-list {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    max-width: 1042px;
  }
  .host-details-popup .reviews-box .reviews-list > .list-item {
    margin-bottom: 0;
    max-width: 100%;
    width: calc(50% - 25px);
  }
  .host-details-popup .reviews-box .reviews-list > .list-item .card-reviews {
    height: 100%;
    padding: 25px;
  }
}
@media screen and (min-width: 1440px) {
  .host-details-popup .gallery-box.container-l {
    padding: 40px 0;
  }
  .host-details-popup .gallery-box .gallery-item {
    width: 400px;
    height: 400px;
    margin-left: 40px;
  }
  .host-details-popup .gallery-box .experience-category .icon-category {
    width: 60px;
    height: 60px;
  }
  .host-details-popup .gallery-box .experience-category .label-category {
    font-size: 18px;
    padding: 6px 10px 5px 65px;
  }
  .host-details-popup .skills-box {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  .host-details-popup .skills-box .skills-box-title {
    margin-bottom: 0;
    width: 30%;
    max-width: 400px;
  }
  .host-details-popup .skills-box .skills-slider {
    padding: 20px;
    gap: 20px;
  }
  .host-details-popup .skills-box .skills-card {
    width: 300px;
    font-size: 18px;
    padding: 30px 25px;
  }
  .host-details-popup .desktop-host-info-block .container-l {
    padding-top: 50px;
    padding-bottom: 50px;
    gap: 90px;
  }
  .host-details-popup .desktop-host-info-block .facts-box .facts-box-tex {
    width: 311px;
    height: 290px;
    padding: 40px 35px 60px;
  }
  .host-details-popup .desktop-host-info-block .reviews-quote-box .card-quote {
    padding: 38px 50px 70px 29px;
    width: 352px;
    height: 255px;
    font-size: 18px;
  }
  .host-details-popup .desktop-host-info-block .reviews-quote-box .card-quote::before {
    width: 75px;
    height: 75px;
    top: -25px;
    left: 4px;
  }
  .host-details-popup .desktop-host-info-block .reviews-quote-box .card-quote::after {
    width: 75px;
    height: 75px;
    bottom: 18px;
    right: -1px;
  }
  .host-details-popup .desktop-host-info-block .author-quote-box .author-quote-box-text {
    padding: 40px 35px 85px;
    width: 399px;
    height: 323px;
  }
}
.icon {
  width: 20px;
  height: 21px;
  display: inline-block;
  background-color: var(--white);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#bookmark") no-repeat center;
}
@media screen and (min-width: 1440px) {
  .icon {
    width: 25px;
    height: 26px;
  }
}

.icon-knows-every-hidden-gem {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#find-on-map") no-repeat center;
}

.icon-loves-food-local-culture {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#noodle") no-repeat center;
}

.icon-cultural-stories-local-insights {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#bookmark") no-repeat center;
}

.icon-knows-the-city-inside-out {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#direction") no-repeat center;
}

.icon-brings-stories-places-life {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#speech-bubble") no-repeat center;
}

.icon-architectural-cultural-explorer {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#skyline") no-repeat center;
}

.icon-nature-city-explorer {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#building") no-repeat center;
}

.icon-local-historian {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#guide-book") no-repeat center;
}

.icon-eco-friendly-advocate {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#ecology") no-repeat center;
}

.icon-photo-spots-explorer {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#photo-pin") no-repeat center;
}

.icon-art-creative-spaces-explorer {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#map-bulb") no-repeat center;
}

.icon-chevron {
  display: inline-block;
  width: 19px;
  height: 12px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#icon-arrow-down") no-repeat center;
  transition: color 0.3s linear, background-color 0.3s linear;
}

.compass {
  display: inline-block;
  width: 25px;
  height: 25px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#compass") no-repeat center;
}

.noodle {
  display: inline-block;
  width: 15px;
  height: 16px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#noodle") no-repeat center;
}

.icon-binoculars {
  display: inline-block;
  width: 15px;
  height: 13px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#icon-binoculars") no-repeat center;
}

.icon-city {
  width: 15px;
  height: 13px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#icon-city") no-repeat center;
}

.icon-flag {
  display: inline-block;
  width: 15px;
  height: 12px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#icon-flag") no-repeat center;
}

.language {
  display: inline-block;
  width: 25px;
  height: 25px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#language") no-repeat center;
}

.pin-with-hard {
  width: 25px;
  height: 25px;
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#pin-with-hard") no-repeat center;
}

.section-hosts {
  padding: 50px 0;
}
.section-hosts .container-l {
  padding: 0 0 0 15px;
}
.section-hosts .title-box {
  text-align: center;
}
.section-hosts .hosts-slider {
  padding-bottom: 50px;
}
.section-hosts .hosts-slider .slick-track {
  display: flex;
  align-items: stretch;
  padding: 15px 0 15px 5px;
  height: 100%;
}
.section-hosts .hosts-slider .slider-item {
  display: flex;
  margin-right: 15px;
  flex-grow: 1;
  height: auto !important;
}
.section-hosts .hosts-slider .slick-arrow {
  top: auto;
  bottom: 0;
  height: 30px;
  width: 30px;
  border-radius: 0;
  padding: 0;
}
.section-hosts .hosts-slider .slick-arrow:hover::after {
  background-color: var(--color-accent-gold);
}
.section-hosts .hosts-slider .slick-arrow::after {
  height: 30px;
  width: 30px;
  background-color: var(--color-primary);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#icon-arrow-down") no-repeat center;
  mask-size: cover;
  margin: 0;
  transition: background-color 0.3s linear;
}
.section-hosts .hosts-slider .slick-arrow.slick-next {
  right: 50%;
  transform: rotate(-90deg) translateY(130%);
}
.section-hosts .hosts-slider .slick-arrow.slick-prev {
  left: 50%;
  transform: rotate(90deg) translateY(130%);
}
.section-hosts .card-host-info {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: var(--grey-light-basic);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  color: var(--base-blue);
  font: 13px "Poppins-Regular", sans-serif;
  height: 100%;
  width: 100%;
  transition: 0.3s;
}
.section-hosts .card-host-info:hover {
  color: var(--base-blue);
  transform: scale(1.02);
}
.section-hosts .card-host-info .card-img {
  border-radius: 10px;
  overflow: hidden;
  height: 200px;
}
.section-hosts .card-host-info .card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.section-hosts .card-host-info .card-category {
  position: absolute;
  top: -15px;
  left: 1px;
  max-width: 98%;
}
.section-hosts .card-host-info .card-category .icon-category {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--white);
  position: absolute;
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--color-primary-light);
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-hosts .card-host-info .card-category .label-category {
  font-size: 11px;
  color: var(--white);
  background-color: var(--color-primary-light);
  border-radius: 10px;
  padding: 5px 10px 4px 45px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.section-hosts .card-host-info .card-body {
  padding: 25px 10px 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.section-hosts .card-host-info .card-body .description {
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3.6em;
  line-height: 1.2em;
}
.section-hosts .card-host-info .card-title {
  margin-bottom: 5px;
}
.section-hosts .card-host-info .reviews-block {
  display: flex;
  align-items: center;
  gap: 2px;
  font: 400 12px "Poppins-Regular", sans-serif;
}
.section-hosts .card-host-info .reviews-block .icon-star {
  width: 15px;
  height: 15px;
  display: block;
  background-color: var(--color-primary-light);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/host-details-popup-8b6a5d62e9.svg#star_yellow") no-repeat center;
  mask-size: cover;
}
.section-hosts .card-host-info .reviews-block .reviews-count {
  color: #2E7D82;
}
.section-hosts .card-host-info .reviews-block .reviews {
  padding: 0;
  color: var(--color-primary-light);
}

@media screen and (min-width: 1024px) {
  .section-hosts .hosts-slider {
    padding-bottom: 70px;
  }
  .section-hosts .hosts-slider .slick-arrow {
    height: 40px;
    width: 40px;
  }
  .section-hosts .hosts-slider .slick-arrow::after {
    height: 40px;
    width: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .section-hosts .hosts-slider {
    padding-bottom: 80px;
  }
  .section-hosts .hosts-slider .slider-item {
    margin-right: 25px;
  }
  .section-hosts .hosts-slider .slick-arrow {
    height: 48px;
    width: 48px;
  }
  .section-hosts .hosts-slider .slick-arrow::after {
    height: 48px;
    width: 48px;
  }
}
@media screen and (min-width: 1440px) {
  .section-hosts .card-host-info {
    font-size: 16px;
  }
  .section-hosts .card-host-info .card-img {
    height: 230px;
  }
  .section-hosts .card-host-info .card-category .icon-category {
    width: 45px;
    height: 45px;
  }
  .section-hosts .card-host-info .card-category .label-category {
    font-size: 14px;
    padding: 6px 10px 5px 50px;
  }
  .section-hosts .card-host-info .card-body {
    padding: 30px 15px 15px;
  }
  .section-hosts .card-host-info .card-body .description {
    margin-bottom: 15px;
  }
  .section-hosts .card-host-info .card-title {
    margin-bottom: 10px;
  }
  .section-hosts .card-host-info .reviews-block {
    font-size: 18px;
    gap: 6px;
  }
  .section-hosts .card-host-info .reviews-block .icon-star {
    width: 20px;
    height: 20px;
  }
}
.travel-difference-section {
  padding: 50px 0;
}
.travel-difference-section .title-block {
  margin-bottom: 40px;
}
.travel-difference-section .title-block .title {
  margin-bottom: 0;
  flex-shrink: 0;
}
.travel-difference-section .title-block .title-comment {
  width: 276px;
  padding: 42px 0 0 70px;
  position: relative;
  margin: 0 auto;
}
.travel-difference-section .title-block .title-comment .icon-arrow {
  width: 60px;
  height: 78px;
  display: block;
  background-color: var(--color-primary);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/travel-difference-section-sprite-935fbb7777.svg#arrow-1") no-repeat center;
  mask-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.travel-difference-section .title-block .title-comment .text-comment {
  font: 500 18px Architects-Daughter, sans-serif;
}
.travel-difference-section .difference-list .difference-list-item {
  margin-left: -15px;
  margin-bottom: 18px;
}
.travel-difference-section .difference-list .difference-list-item:nth-child(2n) {
  margin-left: 0;
  margin-right: -15px;
}
.travel-difference-section .difference-list .difference-list-item:nth-child(2n) .card-difference {
  border-radius: 20px 0 0 20px;
  padding: 27px 42px 27px 27px;
}
.travel-difference-section .difference-list .difference-list-item:nth-child(2n) .card-difference .card-text {
  grid-column: 1;
}
.travel-difference-section .difference-list .difference-list-item:nth-child(2n) .card-difference .card-img {
  grid-column: 2;
}
.travel-difference-section .difference-list .difference-list-item:last-child {
  margin-bottom: 0;
}
.travel-difference-section .card-difference {
  padding: 27px 27px 27px 42px;
  border-radius: 0 20px 20px 0;
  background-color: var(--color-primary-light);
  color: var(--color-neutral-lightest);
  font-size: 15px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: 15px;
}
.travel-difference-section .card-difference .card-title {
  margin-bottom: 0;
  grid-column: span 2;
}
.travel-difference-section .card-difference .card-text {
  grid-row: 2;
  grid-column: 2;
}
.travel-difference-section .card-difference .card-img {
  width: 120px;
  height: 110px;
  flex-shrink: 0;
  background-size: 110% !important;
  grid-row: 2;
  grid-column: 1;
}
.travel-difference-section .card-difference .card-img.paris-bicycle-blue {
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/travel-difference-section-sprite-935fbb7777.svg#paris-bicycle-blue") center no-repeat;
}
.travel-difference-section .card-difference .card-img.shopping-basket {
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/travel-difference-section-sprite-935fbb7777.svg#shopping-basket") center no-repeat;
}
.travel-difference-section .card-difference .card-img.wanderlust-sign {
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/travel-difference-section-sprite-935fbb7777.svg#wanderlust-sign") center no-repeat;
}

@media screen and (min-width: 768px) {
  .travel-difference-section {
    background-color: var(--color-primary-light);
    padding: 80px 0;
  }
  .travel-difference-section .title-block {
    display: flex;
    align-items: center;
    color: var(--color-neutral-lightest);
  }
  .travel-difference-section .title-block .title {
    color: var(--color-neutral-lightest);
    font-size: 29px;
  }
  .travel-difference-section .title-block .title-comment {
    padding: 0 0 0 140px;
    margin: 0;
    width: auto;
  }
  .travel-difference-section .title-block .title-comment .icon-arrow {
    width: 70px;
    height: 100px;
    left: 30px;
    top: -45px;
    transform: rotate(54deg) scale(1, -1);
    background-color: var(--color-neutral-lightest);
  }
  .travel-difference-section .difference-list {
    display: flex;
    gap: 40px;
  }
  .travel-difference-section .difference-list .difference-list-item {
    margin: 0;
    width: 33.3333333333%;
  }
  .travel-difference-section .difference-list .difference-list-item:nth-child(2n) {
    margin: 0;
  }
  .travel-difference-section .difference-list .difference-list-item:nth-child(2n) .card-difference {
    border-radius: 0;
    padding: 0;
  }
  .travel-difference-section .difference-list .card-difference {
    padding: 0;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .travel-difference-section .difference-list .card-difference .card-img {
    margin: 0 auto;
    width: 201px;
    height: 146px;
    background-size: contain !important;
  }
}
@media screen and (min-width: 1024px) {
  .travel-difference-section .title-block {
    margin-bottom: 60px;
  }
  .travel-difference-section .difference-list {
    gap: 50px;
  }
  .travel-difference-section .difference-list .card-difference {
    font-size: 17px;
    gap: 0;
  }
  .travel-difference-section .difference-list .card-difference .card-img {
    width: 261px;
    height: 206px;
    background-size: 98% !important;
    margin-bottom: 35px;
  }
  .travel-difference-section .difference-list .card-difference .card-title {
    font-size: 25px;
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1440px) {
  .travel-difference-section .difference-list {
    gap: 86px;
  }
  .travel-difference-section .title-block .title-comment .text-comment {
    font-size: 22px;
  }
}
.travelers-love-section {
  padding: 50px 0;
  overflow: hidden;
}
.travelers-love-section .title-block {
  position: relative;
  text-align: center;
  margin: 0 auto 40px;
}
.travelers-love-section .title-block .title {
  margin-bottom: 0;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.travelers-love-section .title-block .text-comment {
  font: 500 20px Architects-Daughter, sans-serif;
  color: var(--color-accent-rose);
  border-right: 2px solid var(--color-accent-rose);
  display: inline-block;
  width: 0;
  animation: typing 3s steps(6) infinite, blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
}
@keyframes typing {
  0% {
    width: 0;
  }
  50% {
    width: 6ch;
  }
  95% {
    width: 6ch;
  }
  100% {
    width: 0;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}
.travelers-love-section .travelers-love-list .travelers-love-list-item {
  margin-left: -20px;
  margin-bottom: 18px;
}
.travelers-love-section .travelers-love-list .travelers-love-list-item:nth-child(2n) {
  margin-left: 0;
  margin-right: -20px;
}
.travelers-love-section .travelers-love-list .travelers-love-list-item:nth-child(2n) .card-travelers-love {
  border-radius: 20px 0 0 20px;
  padding: 10px 15px 10px 23px;
  flex-direction: row-reverse;
}
.travelers-love-section .travelers-love-list .travelers-love-list-item:nth-child(2n) .card-travelers-love .card-img {
  transform: translateX(100%);
  opacity: 0;
}
.travelers-love-section .travelers-love-list .travelers-love-list-item:nth-child(2n) .card-travelers-love .card-img.show {
  opacity: 1;
  transform: translateX(0);
}
.travelers-love-section .travelers-love-list .travelers-love-list-item:last-child {
  margin-bottom: 0;
}
.travelers-love-section .card-travelers-love {
  padding: 10px 23px 10px 15px;
  border-radius: 0 20px 20px 0;
  background-color: var(--color-primary-light);
  color: var(--color-neutral-lightest);
  font-size: 15px;
  display: flex;
  align-items: center;
}
.travelers-love-section .card-travelers-love .card-title {
  margin-bottom: 12px;
}
.travelers-love-section .card-travelers-love .card-img {
  width: 146px;
  height: 138px;
  flex-shrink: 0;
  background-size: 100% !important;
  grid-row: 2;
  grid-column: 1;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}
.travelers-love-section .card-travelers-love .card-img.show {
  opacity: 1;
  transform: translateX(0);
}
.travelers-love-section .card-travelers-love .card-img.background-scale {
  background-size: 111% !important;
}
.travelers-love-section .card-travelers-love .card-img.friends {
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/travelers-love-section-sprite-a8694d7fd5.svg#friends") center no-repeat;
}
.travelers-love-section .card-travelers-love .card-img.personalized {
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/travelers-love-section-sprite-a8694d7fd5.svg#personalized") center no-repeat;
}
.travelers-love-section .card-travelers-love .card-img.experiences {
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/travelers-love-section-sprite-a8694d7fd5.svg#experiences") center no-repeat;
}

@media screen and (min-width: 768px) {
  .travelers-love-section {
    padding: 50px 0 0;
  }
  .travelers-love-section .title-block {
    max-width: fit-content;
    margin-bottom: 60px;
  }
  .travelers-love-section .title-block .title {
    font-size: 29px;
  }
  .travelers-love-section .title-block .text-comment {
    font-size: 29px;
    top: -35px;
  }
  .travelers-love-section .travelers-love-list {
    display: flex;
    gap: 15px;
  }
  .travelers-love-section .travelers-love-list .travelers-love-list-item {
    margin: 0;
    width: 33.3333333333%;
  }
  .travelers-love-section .travelers-love-list .travelers-love-list-item:nth-child(2n) {
    margin: 0;
  }
  .travelers-love-section .travelers-love-list .travelers-love-list-item:nth-child(2n) .card-travelers-love {
    border-radius: 20px 20px 0 0;
    flex-direction: column;
    padding: 0px 20px 50px;
  }
  .travelers-love-section .travelers-love-list .travelers-love-list-item:nth-child(2n) .card-travelers-love .card-img {
    transform: translateX(0);
    transform: translateY(100%);
  }
  .travelers-love-section .travelers-love-list .card-travelers-love {
    height: 100%;
    text-align: center;
    flex-direction: column;
    border-radius: 20px 20px 0 0;
    padding: 0px 20px 50px;
  }
  .travelers-love-section .travelers-love-list .card-travelers-love .card-img {
    margin: 0 auto;
    width: 196px;
    height: 210px;
    opacity: 0;
    transform: translateY(100%);
  }
  .travelers-love-section .travelers-love-list .card-travelers-love .card-img.show {
    opacity: 1;
    transform: translateY(0);
  }
  .travelers-love-section .travelers-love-list .card-travelers-love.background-scale {
    background-size: 108% !important;
    background-position: -9px -11px;
  }
}
@media screen and (min-width: 1024px) {
  .travelers-love-section .travelers-love-list {
    gap: 30px;
  }
  .travelers-love-section .travelers-love-list .travelers-love-list-item:nth-child(2n) .card-travelers-love {
    padding: 0 30px 50px;
  }
  .travelers-love-section .travelers-love-list .card-travelers-love {
    font-size: 17px;
    padding: 0 40px 40px;
  }
  .travelers-love-section .travelers-love-list .card-travelers-love .card-title {
    font-size: 25px;
  }
}
.our-guests-comments {
  overflow: hidden;
  padding: 30px 0;
}
.our-guests-comments .title {
  text-align: center;
  margin-bottom: 30px;
}
.our-guests-comments .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.our-guests-comments .content .message {
  width: 210px;
  height: 165px;
  font-size: 11px;
  position: relative;
  z-index: 1;
}
.our-guests-comments .content .message .text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.our-guests-comments .content .message-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary-supportive);
  margin-bottom: 5px;
}
.our-guests-comments .content .message .stars {
  display: flex;
}
.our-guests-comments .content .message .stars .star {
  width: 15px;
  height: 15px;
  background: var(--color-accent-gold);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/our-guests-comments-sprite-1da13000de.svg#star_yellow") no-repeat center;
  mask-size: cover;
}
.our-guests-comments .content .messages-block {
  display: flex;
  position: relative;
}
.our-guests-comments .content .first-message {
  top: -15px;
  padding: 25px 15px 30px 30px;
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/our-guests-comments-sprite-1da13000de.svg#first-message") no-repeat center;
  background-size: cover;
}
.our-guests-comments .content .second-message {
  top: 25px;
  padding: 25px 30px 30px 15px;
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/our-guests-comments-sprite-1da13000de.svg#second-message") no-repeat center;
  background-size: cover;
}
.our-guests-comments .content .third-message {
  top: -30px;
  padding: 45px 15px 30px 30px;
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/our-guests-comments-sprite-1da13000de.svg#third-message") no-repeat center;
  background-size: cover;
}
.our-guests-comments .content .video-block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.our-guests-comments .content .video-block .video-box {
  position: relative;
}
.our-guests-comments .content .video-block video, .our-guests-comments .content .video-block img {
  width: 205px;
  height: 280px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}
.our-guests-comments .content .button-block {
  top: 15px;
  width: 210px;
  height: 165px;
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/our-guests-comments-sprite-1da13000de.svg#button-block") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-left: 15px;
  position: relative;
}
.our-guests-comments .content .button-block .block-title {
  font-family: Architects-Daughter, sans-serif;
  font-size: 15px;
  padding: 0 35px;
  text-align: center;
  margin-bottom: 8px;
}
.our-guests-comments .content .button-block .all-reviews-btn {
  border-radius: 17px;
  font-size: 11px;
  text-transform: uppercase;
  color: var(--color-primary);
  background: var(--color-neutral-lightest);
  padding: 10px 25px;
  border: none;
}
.our-guests-comments .content .button-block .all-reviews-btn:hover, .our-guests-comments .content .button-block .all-reviews-btn:active {
  background-color: var(--color-primary);
  color: var(--color-neutral-lightest);
}

@media screen and (min-width: 768px) {
  .our-guests-comments {
    padding: 50px 0;
  }
  .our-guests-comments .title {
    font-size: 29px;
    margin-bottom: 50px;
  }
  .our-guests-comments .content {
    flex-direction: row;
    gap: 10px;
  }
  .our-guests-comments .content .message {
    width: 267px;
    height: 210px;
    font-size: 15px;
  }
  .our-guests-comments .content .message .message-title {
    margin-bottom: 10px;
  }
  .our-guests-comments .content .message .stars .star {
    width: 15px;
    height: 15px;
  }
  .our-guests-comments .content .messages-block {
    flex-direction: column;
    gap: 20px;
  }
  .our-guests-comments .content .first-message {
    top: auto;
    padding-top: 35px;
  }
  .our-guests-comments .content .second-message {
    top: auto;
    padding: 50px 25px 30px 30px;
    background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/our-guests-comments-sprite-1da13000de.svg#third-message") no-repeat center;
    background-size: cover;
  }
  .our-guests-comments .content .third-message {
    top: auto;
    padding: 30px 24px 40px 20px;
    background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/our-guests-comments-sprite-1da13000de.svg#second-message") no-repeat center;
    background-size: cover;
  }
  .our-guests-comments .content .video-block video, .our-guests-comments .content .video-block img {
    width: 255px;
    height: 350px;
    border-radius: 10px;
  }
  .our-guests-comments .content .button-block {
    top: auto;
    width: 262px;
    height: 206px;
  }
  .our-guests-comments .content .button-block .block-title {
    font-size: 20px;
    padding: 0 35px;
  }
  .our-guests-comments .content .button-block .all-reviews-btn {
    font-size: 20px;
    padding: 15px 35px;
    border-radius: 25px;
  }
}
@media screen and (min-width: 1024px) {
  .our-guests-comments .content .message {
    width: 290px;
    height: 230px;
  }
  .our-guests-comments .content .message .message-title {
    margin-bottom: 25px;
  }
  .our-guests-comments .content .message .stars .star {
    width: 20px;
    height: 20px;
  }
  .our-guests-comments .content .messages-block {
    gap: 25px;
  }
  .our-guests-comments .content .video-block video, .our-guests-comments .content .video-block img {
    width: 382px;
    height: 525px;
    border-radius: 15px;
  }
  .our-guests-comments .content .button-block {
    width: 275px;
    height: 216px;
  }
}
.what-excites-grid-section {
  padding: 30px 0;
}
.what-excites-grid-section .title-block {
  text-align: center;
  margin-bottom: 30px;
}
.what-excites-grid-section .title-block .title {
  margin-bottom: 20px;
}
.what-excites-grid-section .title-block .title-comment {
  font: 500 16px Architects-Daughter, sans-serif;
  position: relative;
  width: 200px;
  padding-right: 50px;
  margin: 0 auto;
  text-align: left;
  transform: rotate(-3deg);
}
.what-excites-grid-section .title-block .title-comment .icon-arrow {
  width: 76px;
  height: 80px;
  display: block;
  background-color: var(--color-accent-rose);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/homepage-sprite-3e664bc5f6.svg#arrow_1") no-repeat center;
  mask-size: cover;
  position: absolute;
  right: -10px;
  top: -5px;
}
.what-excites-grid-section .grid-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}
.what-excites-grid-section .grid-card:hover .card-text, .what-excites-grid-section .grid-card:focus-within .card-text {
  opacity: 0;
  visibility: hidden;
}
.what-excites-grid-section .grid-card:hover .card-description, .what-excites-grid-section .grid-card:focus-within .card-description {
  opacity: 1;
  visibility: visible;
}
.what-excites-grid-section .grid-card::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, rgba(30, 30, 30, 0) 0%, rgba(30, 21, 3, 0.35) 90.5%);
}
.what-excites-grid-section .grid-card .card-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 5px 15px;
  background-color: var(--color-primary-light);
  color: var(--color-neutral-lightest);
  margin-bottom: 0;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s linear, visibility 0.3s linear;
  font-family: Poppins, sans-serif;
}
.what-excites-grid-section .grid-card .card-img {
  max-height: 170px;
  height: 100%;
}
.what-excites-grid-section .grid-card .card-img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.what-excites-grid-section .grid-card .card-description {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 15px;
  background: rgba(11, 16, 55, 0.6);
  color: var(--color-neutral-lightest);
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s linear, visibility 0.3s linear;
}
.what-excites-grid-section .grid-card .card-description p {
  margin: 0 0 12px;
}
.what-excites-grid-section .grid-card .card-description .btn {
  display: block;
  width: fit-content;
  font-size: 12px;
  color: var(--color-neutral-lightest);
  border-radius: 20px;
  border: 1px solid var(--color-neutral-lightest);
  padding: 5px 15px;
  transition: color 0.3s linear, border-color 0.3s linear;
  max-width: 100%;
  text-align: center;
}
.what-excites-grid-section .grid-card .card-description .btn:hover {
  color: var(--color-accent-gold);
  border-color: var(--color-accent-gold);
}
.what-excites-grid-section .grid-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px;
}
.what-excites-grid-section .grid-card-container .grid-card:first-child {
  grid-column: 1/3;
}
.what-excites-grid-section .grid-card-container .grid-card:nth-child(2) {
  grid-column: 1/2;
}
.what-excites-grid-section .grid-card-container .grid-card:nth-child(3) {
  grid-column: 2/3;
}
.what-excites-grid-section .grid-card-container .grid-card:nth-child(4) {
  grid-column: 1/3;
}
.what-excites-grid-section .grid-card-container .grid-card:nth-child(5) {
  grid-column: 1/2;
}
.what-excites-grid-section .grid-card-container .grid-card:nth-child(6) {
  grid-column: 2/3;
}

@media screen and (min-width: 768px) {
  .what-excites-grid-section {
    padding: 50px 0;
  }
  .what-excites-grid-section .title-block {
    margin-bottom: 60px;
  }
  .what-excites-grid-section .title-block .title {
    font-size: 29px;
    margin-bottom: 10px;
  }
  .what-excites-grid-section .title-block .title-comment {
    font-size: 22px;
    width: fit-content;
    padding-right: 0;
    transform: rotate(-1deg);
  }
  .what-excites-grid-section .title-block .title-comment .icon-arrow {
    width: 130px;
    height: 129px;
    right: -136px;
    top: -24px;
  }
  .what-excites-grid-section .grid-card-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 37% 20% 37%;
    height: 500px;
  }
  .what-excites-grid-section .grid-card-container .grid-card:first-child {
    grid-column: 1/3;
    grid-row: 1/3;
  }
  .what-excites-grid-section .grid-card-container .grid-card:nth-child(2) {
    grid-column: 1/2;
    grid-row: 3;
  }
  .what-excites-grid-section .grid-card-container .grid-card:nth-child(3) {
    grid-column: 2/3;
    grid-row: 3;
  }
  .what-excites-grid-section .grid-card-container .grid-card:nth-child(4) {
    grid-column: 3;
    grid-row: 1/4;
  }
  .what-excites-grid-section .grid-card-container .grid-card:nth-child(5) {
    grid-column: 4;
    grid-row: 1/2;
  }
  .what-excites-grid-section .grid-card-container .grid-card:nth-child(6) {
    grid-column: 4;
    grid-row: 2/4;
  }
  .what-excites-grid-section .grid-card .card-img {
    max-height: none;
  }
  .what-excites-grid-section .grid-card .card-text {
    height: 50px;
  }
  .what-excites-grid-section .grid-card .card-description {
    font-size: 16px;
  }
  .what-excites-grid-section .grid-card .card-description .btn {
    font-size: 15px;
    border: 1.5px solid var(--color-neutral-lightest);
  }
}
@media screen and (min-width: 1440px) {
  .what-excites-grid-section .grid-card-container {
    grid-template-rows: 37% 14% 41%;
    grid-template-columns: 21% 21% 26% 26%;
    height: 773px;
    grid-gap: 26px;
  }
  .what-excites-grid-section .grid-card:nth-child(2) .card-description, .what-excites-grid-section .grid-card:nth-child(3) .card-description {
    font-size: 20px;
    padding: 30px;
  }
  .what-excites-grid-section .grid-card:nth-child(2) .card-description .btn, .what-excites-grid-section .grid-card:nth-child(3) .card-description .btn {
    font-size: 20px;
    min-width: 234px;
  }
  .what-excites-grid-section .grid-card:nth-child(2) .card-description p, .what-excites-grid-section .grid-card:nth-child(3) .card-description p {
    margin: 0 0 20px;
  }
  .what-excites-grid-section .grid-card .card-img {
    max-height: none;
  }
  .what-excites-grid-section .grid-card .card-text {
    height: 78px;
    font-size: 25px;
  }
  .what-excites-grid-section .grid-card .card-description {
    font-size: 20px;
    padding: 30px;
  }
  .what-excites-grid-section .grid-card .card-description .btn {
    font-size: 20px;
    padding: 12px 20px;
    min-width: 290px;
    border-radius: 40px;
  }
  .what-excites-grid-section .grid-card .card-description p {
    margin: 0 0 30px;
  }
}
.section-customer-stories {
  padding: 30px 0;
  background-color: var(--color-supportive);
  color: var(--color-text);
}
.section-customer-stories .container-l {
  overflow: hidden;
}
.section-customer-stories .title-box {
  text-align: center;
}
.section-customer-stories .title-box .description {
  font-family: "Poppins-Medium";
  font-weight: 500;
  margin-bottom: 0;
  font-size: 15px;
}
.section-customer-stories .customer-stories-slider {
  display: flex;
  align-items: stretch;
  gap: 10px;
  margin: 0 -15px;
}
.section-customer-stories .customer-stories-slider .swiper-wrapper {
  display: flex;
  padding: 10px 0;
}
.section-customer-stories .customer-stories-slider .swiper-slide {
  width: auto;
}
.section-customer-stories .customer-stories-slider .stories-slider-item {
  flex-shrink: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.section-customer-stories .customer-stories-slider .stories-slider-item.card-rotate-left {
  transform: rotate(-1deg);
}
.section-customer-stories .customer-stories-slider .stories-slider-item.card-rotate-right {
  transform: rotate(3deg);
}
.section-customer-stories .section-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-customer-stories .section-footer .btn-action-text {
  display: block;
  font: 500 18px "Architects-Daughter", sans-serif;
  position: relative;
  padding: 10px 55px 20px 0;
  transform: rotate(-2deg);
  max-width: 306px;
  text-align: center;
  margin-right: 15%;
}
.section-customer-stories .section-footer .btn-action-text::after {
  content: "";
  display: block;
  width: 75px;
  height: 75px;
  background-color: var(--blue-basic);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/customer-stories-section-sprite-1b5fe512d9.svg#arrow") no-repeat center;
  mask-size: contain !important;
  transform: rotate(10deg);
  position: absolute;
  top: 15px;
  right: -14px;
}
.section-customer-stories .stories-card {
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 200px;
}
.section-customer-stories .stories-card .stories-card-img {
  padding: 9px;
  width: 100%;
  height: 183px;
}
.section-customer-stories .stories-card .stories-card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.section-customer-stories .stories-card .stories-card-title {
  padding: 0 10px 15px;
  margin-bottom: 0;
  font: 400 12px "Architects-Daughter", sans-serif;
  text-align: center;
  flex-grow: 1;
}

@media screen and (min-width: 1024px) {
  .section-customer-stories {
    padding: 50px 0;
  }
  .section-customer-stories .title-box {
    display: flex;
    align-items: center;
    gap: 160px;
    margin-bottom: 30px;
  }
  .section-customer-stories .title-box .title {
    margin-bottom: 0;
  }
  .section-customer-stories .title-box .description {
    font-size: 20px;
  }
  .section-customer-stories .customer-stories-slider {
    margin: 0 -15px 0 0;
  }
  .section-customer-stories .customer-stories-slider .stories-card {
    max-width: 400px;
  }
  .section-customer-stories .customer-stories-slider .stories-card .stories-card-img {
    padding: 14px;
    height: 280px;
  }
  .section-customer-stories .customer-stories-slider .stories-card .stories-card-title {
    font-size: 22px;
    padding: 10px 30px 25px;
  }
  .section-customer-stories .section-footer {
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    padding-top: 30px;
  }
  .section-customer-stories .section-footer .btn-action-text {
    margin-right: 0;
    font-size: 26px;
    padding: 10px 110px 10px 0;
    transform: rotate(-3deg);
    max-width: 510px;
  }
  .section-customer-stories .section-footer .btn-action-text::after {
    height: 150px;
    width: 150px;
    mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/customer-stories-section-sprite-1b5fe512d9.svg#arrow5") no-repeat center;
    transform: rotate(4deg);
    top: auto;
    bottom: -50px;
    right: 0;
  }
}
@media screen and (min-width: 1440px) {
  .section-customer-stories .title-box {
    gap: 250px;
  }
  .section-customer-stories .title-box .title {
    margin-bottom: 0;
  }
  .section-customer-stories .title-box .description {
    font-size: 25px;
  }
  .section-customer-stories .container-l {
    padding-right: 0 !important;
  }
  .section-customer-stories .customer-stories-slider {
    margin: 0;
    padding-left: 100px;
  }
  .section-customer-stories .customer-stories-slider .stories-card .stories-card-img {
    padding: 14px;
    height: 320px;
  }
  .section-customer-stories .customer-stories-slider .stories-card .stories-card-title {
    font-size: 20px;
    padding: 10px 20px 20px;
  }
  .section-customer-stories .section-footer .btn-outline {
    font-size: 26px;
    padding: 15px 30px;
    border-radius: 30px;
  }
}
.plan-your-experience {
  padding-top: 30px;
  padding-bottom: 50px;
}
.plan-your-experience .route {
  width: 360px;
  height: 58px;
  display: block;
  background-color: var(--color-accent-rose);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/general-icons-sprite-e299df33e6.svg#route") no-repeat center;
  mask-size: cover;
  position: absolute;
  right: -15px;
  bottom: -43px;
  transform: scale(-1, 1);
}
.plan-your-experience .title-block {
  position: relative;
  margin-bottom: 65px;
}
.plan-your-experience .title-block .text-title {
  display: block;
  width: 195px;
  margin: 0 auto;
  text-align: center;
  font: 500 17px Architects-Daughter, sans-serif;
  transform: rotate(-3deg);
}
.plan-your-experience .a-btn {
  display: block;
  width: 301px;
  border-radius: 20px;
  text-align: center;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .plan-your-experience {
    padding-top: 70px;
    padding-bottom: 50px;
  }
  .plan-your-experience .route {
    width: 410px;
    height: 70px;
    transform: none;
    right: auto;
    left: -410px;
    bottom: -10px;
  }
  .plan-your-experience .title-block {
    margin: 0 auto 65px;
    width: 310px;
  }
  .plan-your-experience .title-block .text-title {
    width: 310px;
    font-size: 24px;
  }
}
@media screen and (min-width: 1200px) {
  .plan-your-experience .route {
    width: 600px;
    height: 85px;
    left: -600px;
    bottom: -20px;
  }
}
@media screen and (min-width: 1600px) {
  .plan-your-experience .route {
    width: 810px;
    height: 110px;
    left: -810px;
    bottom: -30px;
  }
}
.reviews {
  padding: 60px 0 0 0;
  color: var(--blue-basic);
  background-color: var(--grey-light-basic);
  display: flex;
  flex-direction: column;
}
.reviews .title {
  margin-bottom: 50px;
  text-align: left;
}
.reviews .title h2 {
  margin: 0;
}
.reviews .reviews-list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}
.reviews .reviews-list__item {
  width: 100%;
  padding: 15px 25px;
  text-align: center;
  overflow-wrap: anywhere;
}
.reviews .reviews-list__item .review-stars-list {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin-bottom: 20px;
}
.reviews .reviews-list__item .review-stars-list .icon-star {
  width: 27px;
  height: 27px;
}
.reviews .reviews-list__item .review-description-container {
  display: flex;
  flex-direction: column;
  margin: 0 0 25px;
}
.reviews .reviews-list__item .review-description-container .review-description {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.reviews .reviews-list__item .review-description-container .review-description.full {
  display: block;
  -webkit-line-clamp: unset;
}
.reviews .reviews-list__item .review-description-container .show-more-btn {
  margin-top: 10px;
  cursor: pointer;
  width: fit-content;
  align-self: center;
  background: transparent;
  border: 0;
  color: var(--blue-basic);
  transition: color 0.3s linear;
}
.reviews .reviews-list__item .review-description-container .show-more-btn:hover {
  color: var(--yellow-basic);
}
.reviews .reviews-list__item .review-date, .reviews .reviews-list__item .review-city {
  margin: 0;
}
.reviews .reviews-list__item .review-author {
  margin-top: 5px;
}
.reviews .see-more-reviews-btn {
  width: 100%;
  max-width: 200px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
}
.reviews .load-more-reviews {
  display: flex;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  padding-right: 10px;
  font-size: 18px;
  color: var(--blue-basic);
  margin-top: 25px;
}
.reviews .load-more-reviews .arrow-down {
  content: "";
  position: inherit;
  width: 15px;
  height: 15px;
  top: 3px;
  right: -15px;
  background: var(--color-primary);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/reviews-section-sprite-8f43a696ef.svg#icon-arrow-down") center/contain no-repeat;
}

.card-reviews {
  padding: 15px 17px;
  border-radius: 10px;
  border: 1px solid var(--color-supportive);
  font-size: 15px;
  display: flex;
  flex-direction: column;
  color: var(--color-text);
}
.card-reviews .card-header {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-start;
}
.card-reviews .card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.card-reviews .reviews-info {
  font-size: 13px;
  color: var(--color-primary-light);
  text-align: right;
  display: flex;
  gap: 3px;
}
.card-reviews .reviews-content {
  margin: 10px 0;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-reviews .reviews-content.full {
  -webkit-line-clamp: unset;
  max-height: none;
}
.card-reviews .btn-show-more {
  width: fit-content;
  text-decoration: underline;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
  cursor: pointer;
}
.card-reviews .btn-show-more:hover {
  color: var(--yellow-basic);
}
.card-reviews .list-stars {
  display: flex;
  gap: 1px;
}
.card-reviews .icon-star {
  width: 17px;
  height: 17px;
  display: block;
  background-color: var(--yellow-basic);
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/reviews-section-sprite-8f43a696ef.svg#star_yellow") no-repeat center;
  mask-size: cover;
}

@media screen and (min-width: 360px) {
  .reviews .reviews-list__item .review-stars-list .icon-star {
    background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/reviews-section-sprite-8f43a696ef.svg#star_yellow") center/contain no-repeat;
  }
}
@media screen and (min-width: 375px) {
  .reviews .title {
    font-family: Poppins-Bold, Poppins-Medium, sans-serif;
  }
  .reviews .reviews-list__item .review-description {
    font-family: Poppins-Medium, sans-serif;
  }
  .reviews .reviews-list__item .review-date, .reviews .reviews-list__item .review-city {
    font-family: Poppins, Poppins-Medium, sans-serif;
  }
  .reviews .reviews-list__item .review-author {
    font-family: Poppins-Bold, Poppins-Medium, sans-serif;
  }
}
.adjust {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#adjust") no-repeat center;
}

.advanced {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#advanced") no-repeat center;
}

.architecture {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#architecture") no-repeat center;
}

.art {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#art") no-repeat center;
}

.art-gallery {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#art-gallery") no-repeat center;
}

.backpack {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#backpack") no-repeat center;
}

.beer {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#beer") no-repeat center;
}

.bus {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#bus") no-repeat center;
}

.cake {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#cake") no-repeat center;
}

.cancellation {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#cancellation") no-repeat center;
}

.car {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#car") no-repeat center;
}

.categories {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#categories") no-repeat center;
}

.checklist {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#checklist") no-repeat center;
}

.cityscape {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#cityscape") no-repeat center;
}

.cloudy-day {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#cloudy-day") no-repeat center;
}

.compass {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#compass") no-repeat center;
}

.confused {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#confused") no-repeat center;
}

.culture {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#culture") no-repeat center;
}

.customization {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#customization") no-repeat center;
}

.deal {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#deal") no-repeat center;
}

.discount {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#discount") no-repeat center;
}

.duration {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#duration") no-repeat center;
}

.enter-attraction {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#enter-attraction") no-repeat center;
}

.experience {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#experience") no-repeat center;
}

.family {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#family") no-repeat center;
}

.ferry {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#ferry") no-repeat center;
}

.film {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#film") no-repeat center;
}

.flexible-time {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#flexible-time") no-repeat center;
}

.food-money {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#food-money") no-repeat center;
}

.food-allergies {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#food-allergies") no-repeat center;
}

.food-asia {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#food-asia") no-repeat center;
}

.food-italy {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#food-italy") no-repeat center;
}

.generic-food {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#generic-food") no-repeat center;
}

.gin {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#gin") no-repeat center;
}

.hidden-gem {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#hidden-gem") no-repeat center;
}

.highlights {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#highlights") no-repeat center;
}

.hiking {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#hiking") no-repeat center;
}

.history {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#history") no-repeat center;
}

.hoodie {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#hoodie") no-repeat center;
}

.host {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#host") no-repeat center;
}

.host-expertise {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#host-expertise") no-repeat center;
}

.host-selection {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#host-selection") no-repeat center;
}

.hotel {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#hotel") no-repeat center;
}

.info {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#info") no-repeat center;
}

.insights {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#insights") no-repeat center;
}

.knowledge {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#knowledge") no-repeat center;
}

.lake {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#lake") no-repeat center;
}

.language {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#language") no-repeat center;
}

.leader {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#leader") no-repeat center;
}

.market {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#market") no-repeat center;
}

.meeting-point {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#meeting-point") no-repeat center;
}

.metro {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#metro") no-repeat center;
}

.multiple-locations {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#multiple-locations") no-repeat center;
}

.nature {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#nature") no-repeat center;
}

.nature-exploration {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#nature-exploration") no-repeat center;
}

.night-food {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#night-food") no-repeat center;
}

.night {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#night") no-repeat center;
}

.night-mode {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#night-mode") no-repeat center;
}

.payment {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#payment") no-repeat center;
}

.pet-friendly {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#pet-friendly") no-repeat center;
}

.photography {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#photography") no-repeat center;
}

.private {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#private") no-repeat center;
}

.questionnaire {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#questionnaire") no-repeat center;
}

.relaxation {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#relaxation") no-repeat center;
}

.review {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#review") no-repeat center;
}

.safety {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#safety") no-repeat center;
}

.sake {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#sake") no-repeat center;
}

.seasonal {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#seasonal") no-repeat center;
}

.shop {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#shop") no-repeat center;
}

.shopping {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#shopping") no-repeat center;
}

.sunset {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#sunset") no-repeat center;
}

.sustainable {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#sustainable") no-repeat center;
}

.tailored {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#tailored") no-repeat center;
}

.taxi {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#taxi") no-repeat center;
}

.tequila {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#tequila") no-repeat center;
}

.tickets {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#tickets") no-repeat center;
}

.tips {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#tips") no-repeat center;
}

.tourist-attraction {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#tourist-attraction") no-repeat center;
}

.train {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#train") no-repeat center;
}

.umbrella {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#umbrella") no-repeat center;
}

.vegetarian {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#vegetarian") no-repeat center;
}

.walking {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#walking") no-repeat center;
}

.wheelchair {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#wheelchair") no-repeat center;
}

.whiskey {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#whiskey") no-repeat center;
}

.wine {
  mask: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/icons-library-sprite-429cd51c84.svg#wine") no-repeat center;
}

@media screen and (min-width: 768px) {
  .popular-destination-container {
    padding: 0 15px !important;
  }
  .popular-destination-container .title-popular-destinations {
    font-size: 24px;
    padding: 0 5px;
    text-align: left;
  }
}
@media screen and (min-width: 768px) and (min-width: 992px) {
  .popular-destination-container .title-popular-destinations {
    padding: 0 15px;
  }
}
@media screen and (min-width: 768px) and (min-width: 1200px) {
  .popular-destination-container .title-popular-destinations {
    font-size: 26px;
    padding: 0;
  }
}
@media screen and (min-width: 768px) {
  .popular-destination-container .grid-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
    margin-top: 30px;
  }
  .popular-destination-container .grid-wrapper.desktop-section {
    display: block;
  }
  .popular-destination-container .grid-wrapper.mobile-section {
    display: none;
  }
  .popular-destination-container .grid-wrapper .country-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 0;
    margin-bottom: 0;
  }
  .popular-destination-container .grid-wrapper .country-list .grid-container-left {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: -8px;
  }
  .popular-destination-container .grid-wrapper .country-list .grid-container-left .index-0 {
    grid-column: 1/3;
    grid-row: 1/3;
    margin: 8px 6px 6px 6px;
    max-height: 97%;
  }
  .popular-destination-container .grid-wrapper .country-list .grid-container-left .index-1 {
    grid-column: auto;
    grid-row: 4/3;
    padding-right: 3px;
    margin: 0 0 6px 6px;
    max-height: 93%;
    padding-top: 0;
  }
  .popular-destination-container .grid-wrapper .country-list .grid-container-left .index-2 {
    grid-column: 2/4;
    grid-row: 3;
    margin: 0 6px 6px 6px;
    max-height: 93%;
    padding-top: 0;
  }
  .popular-destination-container .grid-wrapper .country-list .grid-container-right {
    display: grid;
    min-height: 537px;
    height: 100%;
    grid-template-columns: 1fr 1fr;
  }
  .popular-destination-container .grid-wrapper .country-list .grid-container-right .index-3 {
    grid-column: 1/4;
    grid-row: 1/1;
    margin: 8px 5px 0 0;
    padding-bottom: 5px;
  }
  .popular-destination-container .grid-wrapper .country-list .grid-container-right .index-4 {
    grid-column: 1/2;
    grid-row: 2/2;
    max-height: 96%;
    padding-right: 6px;
  }
  .popular-destination-container .grid-wrapper .country-list .grid-container-right .index-5 {
    grid-column: 2/4;
    grid-row: 2;
    max-height: 96%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .popular-destination-container .grid-wrapper .country-list .grid-container-right .index-6 {
    display: inline-block;
    grid-column: 1/4;
    grid-row: 3/3;
    max-height: 92.4%;
    padding-right: 6px;
    padding-top: 0;
  }
  .popular-destination-container .grid-wrapper .country-list .cover {
    padding: 3px 3px 0 3px;
    max-width: none;
    margin: 0;
  }
  .popular-destination-container .grid-wrapper .country-list .cover .country-item {
    padding: 0;
    position: relative;
  }
  .popular-destination-container .grid-wrapper .country-list .cover .country-item .subst {
    position: absolute;
    z-index: 3;
    min-width: 150px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -60%);
    border: none;
  }
  .popular-destination-container .grid-wrapper .country-list .cover .country-item .name-city {
    font-size: 20px;
  }
  .popular-destination-container .grid-wrapper .country-list .cover .country-item .country-item-label {
    position: absolute;
    top: 50px;
    right: 20px;
    padding: 20px 15px;
    max-width: 85%;
    border-radius: 10px;
    background: rgba(250, 250, 250, 0.5);
    color: var(--color-primary);
    font: 300 18px "Poppins-Light", sans-serif;
    z-index: 2;
    text-align: center;
    display: block;
  }
  .popular-destination-container .grid-wrapper .country-list .cover .country-item .country-item-label.d-none {
    display: none;
  }
  .popular-destination-container .grid-wrapper .country-list .cover .country-item:hover .img-content img {
    transform: scale(1.01);
    filter: revert;
  }
  .popular-destination-container .button-area {
    width: 285px;
    height: 60px;
  }
  .popular-destination-container .button-area .a-btn {
    font-size: 16px;
  }
  .reviews {
    padding: 50px 0 0 0;
    background-color: transparent;
  }
  .reviews .title {
    margin-bottom: 50px;
  }
  .reviews .reviews-list {
    margin: 0;
    gap: 30px;
  }
  .reviews .reviews-list__item {
    width: calc(50% - 15px);
    box-shadow: none;
    padding: 30px 0;
    margin-bottom: 0;
    text-align: left;
  }
  .reviews .reviews-list__item .review-stars-list {
    justify-content: left;
  }
  .reviews .reviews-list__item .review-stars-list .icon-star {
    width: 22px;
    height: 17px;
  }
  .reviews .reviews-list__item .review-description-container .review-description {
    line-height: 21px;
    text-align: justify;
  }
}
@media screen and (min-width: 768px) and (min-width: 992px) {
  .reviews .reviews-list__item .review-description-container .review-description {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (min-width: 768px) {
  .reviews .reviews-list__item .review-description-container .show-more-btn {
    align-self: flex-end;
  }
  .reviews .reviews-list__item .review-date {
    font-size: 16px;
  }
  .reviews .reviews-list__item .review-city {
    font-size: 16px;
  }
  .reviews .reviews-list__item .review-author {
    font-size: 16px;
  }
  .reviews .see-more-reviews-btn {
    margin: 60px auto;
    max-width: 270px;
  }
  .reviews .load-more-reviews {
    display: inline-block;
  }
  .reviews .load-more-reviews .arrow-down {
    position: absolute;
    top: 3px;
    left: 179px;
  }
  .reviews .reviews-link {
    text-decoration: underline;
    border: 0;
    margin: 80px 0 0 0;
    justify-content: flex-start;
    font-family: Poppins-SemiBold, sans-serif;
  }
  .reviews .reviews-link:hover {
    color: var(--blue-basic);
    background-color: var(--grey-light-basic);
  }
  .card-reviews {
    border: 0;
    font-size: 17px;
  }
  .card-reviews .card-header {
    flex-direction: row;
  }
  .card-reviews .card-header .reviews-info {
    flex-direction: column;
    font-size: 16px;
    text-align: left;
    text-align: right;
  }
  .card-reviews .card-header .reviews-info .reviews-info-name {
    font: 600 18px "Poppins-SemiBold", sans-serif;
  }
  .card-reviews .btn-show-more {
    font-size: 18px;
  }
}
.section-customer-stories .customer-stories-slider {
  padding-left: 0;
}
.section-customer-stories .title-box {
  justify-content: flex-start;
}

.seacrch-placeholder {
  height: 0;
  overflow: hidden;
}