.travel-difference-section {
    padding: 50px 0;

    .title-block {
        margin-bottom: 40px;

        .title {
            margin-bottom: 0;
            flex-shrink: 0;
        }
        
        .title-comment {
            width: 276px;
            padding: 42px 0 0 70px;
            position: relative;
            margin: 0 auto;

            .icon-arrow {
                width: 60px;
                height: 78px;
                display: block;
                background-color: var(--color-primary);
                mask: url($travel-difference-section-sprite + '#arrow-1') no-repeat center;
                mask-size: cover;
                position: absolute;
                left: 0;
                top: 0;
            }

            .text-comment {
                font: 500 18px Architects-Daughter, sans-serif;
            }
        }
    }

    .difference-list {
        .difference-list-item {
            margin-left: -15px;
            margin-bottom: 18px;

            &:nth-child(2n) {
                margin-left: 0;
                margin-right: -15px;

                .card-difference {
                    border-radius: 20px 0 0 20px;
                    padding: 27px 42px 27px 27px;

                    .card-text {
                        grid-column: 1;
                    }

                    .card-img {
                        grid-column: 2;
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .card-difference {
        padding: 27px 27px 27px 42px;
        border-radius: 0 20px 20px 0;
        background-color: var(--color-primary-light);
        color: var(--color-neutral-lightest);
        font-size: 15px;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        grid-gap: 15px;

        .card-title {
            margin-bottom: 0;
            grid-column: span 2;
        }

        .card-text {
            grid-row: 2;
            grid-column: 2;
        }

        .card-img {
            width: 120px;
            height: 110px;
            flex-shrink: 0;
            background-size: 110% !important;
            grid-row: 2;
            grid-column: 1;

            &.paris-bicycle-blue {
                background: url($travel-difference-section-sprite + '#paris-bicycle-blue') center no-repeat;
            }

            &.shopping-basket {
                background: url($travel-difference-section-sprite + '#shopping-basket') center no-repeat;
            }

            &.wanderlust-sign {
                background: url($travel-difference-section-sprite + '#wanderlust-sign') center no-repeat;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .travel-difference-section {
        background-color: var(--color-primary-light);
        padding: 80px 0;

        .title-block {
            display: flex;
            align-items: center;
            color: var(--color-neutral-lightest);

            .title {
                color: var(--color-neutral-lightest);
                font-size: 29px;
            }

            .title-comment {
                padding: 0 0 0 140px;
                margin: 0;
                width: auto;

                .icon-arrow {
                    width: 70px;
                    height: 100px;
                    left: 30px;
                    top: -45px;
                    transform: rotate(54deg) scale(1, -1);
                    background-color: var(--color-neutral-lightest);
                }
            }
        }

        .difference-list {
            display: flex;
            gap: 40px;

            .difference-list-item {
                margin: 0;
                width: calc(100% / 3);

                &:nth-child(2n) {
                    margin: 0;
    
                    .card-difference {
                        border-radius: 0;
                        padding: 0;
                    }
                }
            }
            
            .card-difference {
                padding: 0;
                height: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;

                .card-img {
                    margin: 0 auto;
                    width: 201px;
                    height: 146px;
                    background-size: contain !important;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .travel-difference-section {
        .title-block {
            margin-bottom: 60px;
        }

        .difference-list {
            gap: 50px;
            
            .card-difference {
                font-size: 17px;
                gap: 0;

                .card-img {
                    width: 261px;
                    height: 206px;
                    background-size: 98% !important;
                    margin-bottom: 35px;
                }

                .card-title {
                    font-size: 25px;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .travel-difference-section {
        .difference-list {
            gap: 86px;
        }

        .title-block {
            .title-comment {
                .text-comment {
                    font-size: 22px;
                }
            }
        }
    }
}