@import 'icons';

.host-details-popup {
    overflow: hidden;
    height: 100vh;
    font: 400 15px 'Poppins-Regular', sans-serif;
    line-height: 1.4;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 99999;
    transition: left 0.3s linear, background-color 0.5s linear;
    background-color: rgba(0, 0, 0, 0.5);

    &.hidden-popup {
        left: -100%;
        background-color: transparent;
    }

    .popup-wrapper {
        width: 100%;
    }
    
    .popup-content {
        padding: 0 0 20px;
        margin: 0;
        border: 0;
        width: 100%;
        overflow-y: scroll;
        max-height: calc(100vh - 72px);
    }

    .popup-header {
        background-color: var(--color-primary);
    }

    .btn-back {
        display: flex;
        align-items: center;
        padding: 31px 20px 23px;
        color: var(--white);
        background-color: var(--color-primary);
        font-size: 15px;
        width: fit-content;
        cursor: pointer;

        &:hover {
            color: var(--yellow-basic);
            
            .icon-chevron {
                background-color: var(--yellow-basic);
            }
        }

        .icon-chevron {
            background-color: var(--white);
            transform: rotate(90deg);
            margin-right: 10px;
        }
    } 

    .gallery-box {
        position: relative;
        overflow-x: hidden;

        &.container-l {
            padding: 30px 0;
        }

        .experience-category {
            position: absolute;
            left: 20px;
            bottom: 22px;
            max-width: 98%;

            .icon-category {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 2px solid var(--white);
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: var(--grey-dark);
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            .label-category {
                font-size: 11px;
                color: var(--white);
                background-color: var(--grey-dark);
                border-radius: 20px 10px 10px 20px;
                padding: 5px 10px 4px 45px;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }
        }

        .gallery-list {
            display: flex;
            overflow-x: auto;
        }

        .gallery-item {
            width: 250px;
            height: 250px;
            flex-shrink: 0;
            border-radius: 15px;
            overflow: hidden;
            margin-left: 20px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
            }
        }
    }

    .description-box {
        padding: 10px 20px 30px;
        line-height: 1.4;

        .popup-title {
            display: flex;
            align-items: center;
            gap: 15px;
            max-width: 800px;

            .title {
                font: 400 21px Architects-Daughter,sans-serif;
                margin-bottom: 0;
            }

            .description {
                max-width: 800px;
            }
        }

        .additional-information {
            transition: height 0.3s linear visibility 0.3s linear;
            max-width: 800px;
            margin: 0;
        }  
    }
    
    .reviews-block {
        display: flex;
        align-items: center;
        gap: 2px;
        font: 400 12px 'Poppins-Regular', sans-serif;

        .icon-star {
            width: 15px;
            height: 15px;
            display: block;
            background-color: var(--blue-basic);
            mask: url($host-details-popup + '#star_yellow') no-repeat center;
            mask-size: cover;
        }

        .reviews {
            display: block;
            padding: 0;
        }

        .reviews-count {
            color: var(--grey-dark);
        }
    }

    .skills-box {
        padding: 20px 20px 50px;
        overflow-x: hidden;

        .skills-slider {
            display: flex;
            gap: 15px;
            overflow-x: auto;
            margin-right: -20px;
            padding: 20px 5px;

            .list-item {
                .skills-card {
                    height: 100%;
                }
            }
        }
        
        .icon {
            background-color: var(--blue-basic);
        }

        .skills-card {
            border-radius: 10px;
            background-color: var(--grey-light-basic);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            padding: 25px 20px;
            font-size: 15px;
            width: 250px;
            line-height: 1.6;

            .card-title {
                display: flex;
                align-items: center;
                gap: 5px;
                margin-bottom: 15px;
            }

            .explore-list {
                .list-item {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }

            .card-list {
                .list-item {
                    padding-left: 15px;
                    position: relative;

                    &::before {
                        content: "\2022";
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 4px;
                        height: 4px;
                    }
                }
            }
        }
    }

    .desktop-block {
        display: none !important;
    }

    .facts-box {
        padding: 36px 20px;

        .title {
            margin-bottom: 8px;
            color: var(--white);
        }
    }

    .reviews-quote-box {
        padding: 62px 20px 51px;

        .card-quote {
            padding: 32px 27px 58px 14px;
            position: relative;
            width: 277px;
            height: 217px;
            z-index: 1;
            font-size: 15px;
            margin: 0 auto;
            text-align: center;
            font-style: italic;
            line-height: 1.2;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &::before,
            &::after {
                content: '';
                display: block;
                width: 65px;
                height: 65px;
                background-color: var(--blue-basic);
                mask: url($host-details-popup + '#quote') no-repeat center;
                mask-size: cover;
                position: absolute;
                top: -22px;
                left: -13px;
            }

            &::after {
                top: auto;
                left: auto;
                bottom: 8px;
                right: 0;
                transform: rotate(180deg);
            }

            blockquote {
                margin: 0;
            }

            .quote-background {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: -1;
                top: 0;
                left: -4px;
            }

            .quote-author {
                font-family: 'Poppins-SemiBold';
                font-style: normal;
            }
        }
    }

    .author-quote-box {
        padding: 55px 34px 55px 36px;
    }

    .reviews-box {
        &.container-l {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        
        .reviews-list {
            margin-bottom: 20px;

            >.list-item {
                margin-bottom: 13px;
                max-width: 354px;
            }
        }

        .card-reviews {
            border: 1px solid var(--grey-dark);

            .reviews-info {
                gap: 0;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .host-details-popup {
        font-size: 17px;

        .btn-back {
            font-size: 24px;

            .icon-chevron {
                width: 40px;
                height: 40px;
            }
        }

        .gallery-box {
            &.container-l {
                padding: 30px 0;
            }

            .gallery-item {
                width: 320px;
                height: 320px;
            }

            .experience-category {
                position: absolute;
                left: 20px;
                bottom: 22px;
                max-width: 98%;
    
                .icon-category {
                    width: 45px;
                    height: 45px;
                }
        
                .label-category {
                    font-size: 14px;
                    padding: 6px 10px 5px 50px;
                }
            }
        }

        .description-box {
    
            .popup-title {
                gap: 30px;
    
                .title {
                    font-size: 40px;
                }
            } 
        }

        .reviews-block {
            gap: 5px;
            font-size: 22px;
    
            .icon-star {
                width: 24px;
                height: 24px;
            }
        }

        .skills-box {
            .skills-slider {
                overflow-x: visible;
            }

            .skills-card {
                width: 285px;
                font-size: 18px;
            }
        }

        .mobile-block {
            display: none !important;
        }

        .desktop-block {
            display: block !important;
        }

        .desktop-host-info-block {
            background-color: var(--blue-basic);
            color: var(--white);

            .container-l {
                padding-top: 30px;
                padding-bottom: 30px;
                display: flex;
                justify-content: center;
                gap: 40px;
            }

            .title {
                margin-bottom: 30px;
                text-align: center;
            }

            .facts-box {
                padding: 0;

                .facts-box-tex {
                    position: relative;
                    padding: 36px 30px 50px;
                    width: 271px;
                    height: 250px;
                    display: flex;
                    flex-direction: column;

                    p {
                        margin: 0;
                    }
                }

                .facts-box-background {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: -4px;
                }
            }

            .reviews-quote-box {
                padding: 0;

                .card-quote {
                    padding: 32px 27px 58px 14px;
                    width: 277px;
                    height: 217px;
        
                    &::before,
                    &::after {
                        background-color: var(--yellow-basic);
                    }
                }
            }

            .author-quote-box {
                background-color: transparent;
                padding: 0;

                .author-quote-box-text {
                    position: relative;
                    padding: 30px 30px 65px 35px;
                    width: 358px;
                    height: 283px;
                    display: flex;
                    flex-direction: column;
                }

                .author-quote-box-background {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: -4px;
                }
            }
        }

        .reviews-box {
            &.container-l {
                padding-top: 50px;
                padding-bottom: 50px;
            }

            .reviews-list {
                display: flex;
                flex-wrap: wrap;
                gap: 50px;
                max-width: 1042px;

                >.list-item {
                    margin-bottom: 0;
                    max-width: 100%;
                    width: calc(100% / 2 - 25px);


                    .card-reviews {
                        height: 100%;
                        padding: 25px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .host-details-popup {

        .gallery-box {
            &.container-l {
                padding: 40px 0;
            }

            .gallery-item {
                width: 400px;
                height: 400px;
                margin-left: 40px;
            }

            .experience-category {
    
                .icon-category {
                    width: 60px;
                    height: 60px;
                }
        
                .label-category {
                    font-size: 18px;
                    padding: 6px 10px 5px 65px;
                }
            }
        }

        .skills-box {
            display: flex;
            align-items: center;
            gap: 30px;

            .skills-box-title {
                margin-bottom: 0;
                width: 30%;
                max-width: 400px;
            }

            .skills-slider {
                padding: 20px;
                gap: 20px;
            }

            .skills-card {
                width: 300px;
                font-size: 18px;
                padding: 30px 25px;
            }
        }

        .desktop-host-info-block {
            .container-l {
                padding-top: 50px;
                padding-bottom: 50px;
                gap: 90px;
            }

            .facts-box {
                .facts-box-tex {
                    width: 311px;
                    height: 290px;
                    padding: 40px 35px 60px;
                }
            }

            .reviews-quote-box {
                .card-quote {
                    padding: 38px 50px 70px 29px;
                    width: 352px;
                    height: 255px;
                    font-size: 18px;
        
                    &::before {
                        width: 75px;
                        height: 75px;
                        top: -25px;
                        left: 4px;
                    }

                    &::after {
                        width: 75px;
                        height: 75px;
                        bottom: 18px;
                        right: -1px;
                    }
                }
            }

            .author-quote-box {
                .author-quote-box-text {
                    padding: 40px 35px 85px;
                    width: 399px;
                    height: 323px;
                }
            }
        }
    }
}