@import '../common-variables.scss';

.reviews {
    padding: 60px 0 0 0;
    color: var(--blue-basic);
    background-color: var(--grey-light-basic);
    display: flex;
    flex-direction: column;

    .title {
        margin-bottom: 50px;
        text-align: left;

        h2 {
            margin: 0;
        }
    }

    .reviews-list {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;

        &__item {
            width: 100%;
            padding: 15px 25px;
            text-align: center;
            overflow-wrap: anywhere;

            .review-stars-list {
                display: flex;
                justify-content: center;
                list-style-type: none;
                margin-bottom: 20px;

                .icon-star {
                    width: 27px;
                    height: 27px;
                }
            }

            .review-description-container {
                display: flex;
                flex-direction: column;
                margin: 0 0 25px;

                .review-description {
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: 0;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &.full {
                        display: block;
                        -webkit-line-clamp: unset;
                    }
                }

                .show-more-btn {
                    margin-top: 10px;
                    cursor: pointer;
                    width: fit-content;
                    align-self: center;
                    background: transparent;
                    border: 0;
                    color: var(--blue-basic);
                    transition: color 0.3s linear;

                    &:hover {
                        color: var(--yellow-basic);
                    }
                }
            }

            .review-date, .review-city {
                margin: 0;
            }

            .review-author {
                margin-top: 5px;
            }
        }
    }

    .see-more-reviews-btn {
        width: 100%;
        max-width: 200px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px auto;
    }

    .load-more-reviews {
        display: flex;
        justify-content: center;
        position: relative;
        text-transform: uppercase;
        padding-right: 10px;
        font-size: 18px;
        color: var(--blue-basic);
        margin-top: 25px;

        .arrow-down {
            content: '';
            position: inherit;
            width: 15px;
            height: 15px;
            top: 3px;
            right: -15px;
            background: var(--color-primary);
            mask: url($reviews-section-sprite + '#icon-arrow-down') center / contain no-repeat;
        }
    }
}

.card-reviews {
    padding: 15px 17px;
    border-radius: 10px;
    border: 1px solid var(--color-supportive);
    font-size: 15px;
    display: flex;
    flex-direction: column;
    color: var(--color-text);

    .card-header {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: flex-start;
    }

    .card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }

    .reviews-info {
        font-size: 13px;
        color: var(--color-primary-light);
        text-align: right;
        display: flex;
        gap: 3px;
    }

    .reviews-content {
        margin: 10px 0;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        &.full {
            -webkit-line-clamp: unset;
            max-height: none;
        }
    }

    .btn-show-more {
        width: fit-content;
        text-decoration: underline;
        font-family: 'Poppins-SemiBold';
        font-size: 15px;
        cursor: pointer;

        &:hover {
            color: var(--yellow-basic);
        }
    }

    .list-stars {
        display: flex;
        gap: 1px;
    }

    .icon-star {
        width: 17px;
        height: 17px;
        display: block;
        background-color: var(--yellow-basic);
        mask: url($reviews-section-sprite + '#star_yellow') no-repeat center;
        mask-size: cover;
    }
}

@media screen and (min-width: 360px) {
    .reviews {
        .reviews-list {
            &__item {
                .review-stars-list {
                    .icon-star {
                        background: url($reviews-section-sprite + '#star_yellow') center / contain no-repeat;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 375px) {
    .reviews {

        .title {
            font-family: Poppins-Bold, Poppins-Medium, sans-serif;
        }

        .reviews-list {
            &__item {

                .review-description {
                    font-family: Poppins-Medium, sans-serif;
                }

                .review-date, .review-city {
                    font-family: Poppins, Poppins-Medium, sans-serif;
                }

                .review-author {
                    font-family: Poppins-Bold, Poppins-Medium, sans-serif;
                }
            }
        }
    }
}