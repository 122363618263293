.section-as-seen-in {
    padding: 32px 0 46px;

    .title-block {
        text-align: center;
    }

    .list-publications {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .list-item {
            width: calc(100% / 2 - 26px);
            display: flex;
            align-items: center;
            height: 120px;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }
        }
        
        .the-guardian-logo {
            background: url($homepage-sprite + '#the-guardian-logo') center no-repeat;
            background-size: 50% !important;
        }

        .national-geographic-logo {
            background: url($homepage-sprite + '#national-geographic-logo') center no-repeat;
            background-size: 140% !important;
        }

        .the-times-logo {
            background: url($homepage-sprite + '#the-times-logo') center no-repeat;
            background-size: 70% !important;
        }

        .vg-tv-logo {
            background: url($homepage-sprite + '#vg-tv-logo') center no-repeat;
            background-size: 60% !important;
        }
    }
}

@media screen and (min-width: 580px) {
    .section-as-seen-in {
        .list-publications {
            .list-item {
                height: 150px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .section-as-seen-in {
        padding: 55px 0 90px;

        .title-block .title {
            margin-bottom: 65px;
        }

        .list-publications {
            gap: 35px;

            .list-item {
                width: calc(100% / 4 - 35px);
            }

            .the-guardian-logo {
                background-size: contain !important;
            }
    
            .the-times-logo {
                background-size: contain !important;
            }
    
            .vg-tv-logo {
                background-size: contain !important;
            }
        }
    }
}