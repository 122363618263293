.travelers-love-section {
    padding: 50px 0;
    overflow: hidden;

    .title-block {
        position: relative;
        text-align: center;
        margin: 0 auto 40px;

        .title {
            margin-bottom: 0;
            flex-shrink: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        
        .text-comment {
            font: 500 20px Architects-Daughter, sans-serif;
            color: var(--color-accent-rose);
            border-right: 2px solid var(--color-accent-rose);
            display: inline-block;
            width: 0;
            animation: typing 3s steps(6) infinite, 
                       blink 0.5s step-end infinite alternate;
            white-space: nowrap;
            overflow: hidden;
        }
        
        @keyframes typing {
            0% {
                width: 0;
            }
            50% {
                width: 6ch;
            }
            95% {
                width: 6ch;
            }
            100% {
                width: 0;
            }
        }
        
        @keyframes blink {
            50% {
                border-color: transparent;
            }
        }
    }

    .travelers-love-list {
        .travelers-love-list-item {
            margin-left: -20px;
            margin-bottom: 18px;

            &:nth-child(2n) {
                margin-left: 0;
                margin-right: -20px;

                .card-travelers-love {
                    border-radius: 20px 0 0 20px;
                    padding: 10px 15px 10px 23px;
                    flex-direction: row-reverse;

                    .card-img {
                        transform: translateX(100%);
                        opacity: 0;

                        &.show {
                            opacity: 1;
                            transform: translateX(0);
                        }
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .card-travelers-love {
        padding: 10px 23px 10px 15px;
        border-radius: 0 20px 20px 0;
        background-color: var(--color-primary-light);
        color: var(--color-neutral-lightest);
        font-size: 15px;
        display: flex;
        align-items: center;

        .card-title {
            margin-bottom: 12px;
        }

        .card-img {
            width: 146px;
            height: 138px;
            flex-shrink: 0;
            background-size: 100% !important;
            grid-row: 2;
            grid-column: 1;
            opacity: 0;
            transform: translateX(-100%);
            transition: opacity 0.8s ease-out, transform 0.8s ease-out;

            &.show {
                opacity: 1;
                transform: translateX(0);
            }

            &.background-scale {
                background-size: 111% !important;
            }

            &.friends {
                background: url($travelers-love-section-sprite + '#friends') center no-repeat;
            }

            &.personalized {
                background: url($travelers-love-section-sprite + '#personalized') center no-repeat;
            }

            &.experiences {
                background: url($travelers-love-section-sprite + '#experiences') center no-repeat;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .travelers-love-section {
        padding: 50px 0 0;

        .title-block {
            max-width: fit-content;
            margin-bottom: 60px;

            .title {
                font-size: 29px;
            }

            .text-comment {
                font-size: 29px;
                top: -35px;
            }
        }

        .travelers-love-list {
            display: flex;
            gap: 15px;

            .travelers-love-list-item {
                margin: 0;
                width: calc(100% / 3);

                &:nth-child(2n) {
                    margin: 0;

                    .card-travelers-love {
                        border-radius: 20px 20px 0 0;
                        flex-direction: column;
                        padding: 0px 20px 50px;

                        .card-img {
                            transform: translateX(0);
                            transform: translateY(100%);
                        }
                    }
                }
            }
            
            .card-travelers-love {
                height: 100%;
                text-align: center;
                flex-direction: column;
                border-radius: 20px 20px 0 0;
                padding: 0px 20px 50px;

                .card-img {
                    margin: 0 auto;
                    width: 196px;
                    height: 210px;
                    opacity: 0;
                    transform: translateY(100%);

                    &.show {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                &.background-scale {
                    background-size: 108% !important;
                    background-position: -9px -11px;
                }
                
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .travelers-love-section {
        .travelers-love-list {
            gap: 30px;

            .travelers-love-list-item {
                &:nth-child(2n) {
                    .card-travelers-love {
                        padding: 0 30px 50px;
                    }
                }
            }
            
            .card-travelers-love {
                font-size: 17px;
                padding: 0 40px 40px;

                .card-title {
                    font-size: 25px;
                }
            }
        }
    }
}