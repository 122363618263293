.popular-destination-container {
    padding: 0 15px !important;


    .title-popular-destinations {
        font-size: 24px;
        padding: 0 5px;
        text-align: left;

        @media screen and (min-width: 992px) {
            padding: 0 15px;
        }

        @media screen and (min-width: 1200px) {
            font-size: 26px;
            padding: 0;
        }
    }

    .grid-wrapper {
        position: relative;
        display: flex;
        width: 100%;
        overflow: hidden;
        margin-top: 30px;

        &.desktop-section {
            display: block;
        }

        &.mobile-section {
            display: none;
        }

        .country-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 0;
            margin-bottom: 0;

            .grid-container-left {
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin-left: -8px;

                .index-0 {
                    grid-column: 1/3;
                    grid-row: 1/3;
                    margin: 8px 6px 6px 6px;
                    max-height: 97%;
                }

                .index-1 {
                    grid-column: auto;
                    grid-row: 4/3;
                    padding-right: 3px;
                    margin: 0 0 6px 6px;
                    max-height: 93%;
                    padding-top: 0;
                }

                .index-2 {
                    grid-column: 2/4;
                    grid-row: 3;
                    margin: 0 6px 6px 6px;
                    max-height: 93%;
                    padding-top: 0;
                }
            }

            .grid-container-right {
                display: grid;
                min-height: 537px;
                height: 100%;
                grid-template-columns: 1fr 1fr;

                .index-3 {
                    grid-column: 1/4;
                    grid-row: 1/1;
                    margin: 8px 5px 0 0;
                    padding-bottom: 5px;
                }

                .index-4 {
                    grid-column: 1/2;
                    grid-row: 2/2;
                    max-height: 96%;
                    padding-right: 6px;
                }

                .index-5 {
                    grid-column: 2/4;
                    grid-row: 2;
                    max-height: 96%;
                    padding-right: 6px;
                    padding-left: 6px;
                }

                .index-6 {
                    display: inline-block;
                    grid-column: 1/4;
                    grid-row: 3/3;
                    max-height: 92.4%;
                    padding-right: 6px;
                    padding-top: 0;
                }
            }

            .cover {
                padding: 3px 3px 0 3px;
                max-width: none;
                margin: 0;

                .country-item {
                    padding: 0;
                    position: relative;

                    .subst {
                        position: absolute;
                        z-index: 3;
                        min-width: 150px;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        top: 58%;
                        left: 50%;
                        transform: translate(-50%, -60%);
                        border: none;
                    }

                    .name-city {
                        font-size: 20px;
                    }

                    .country-item-label {
                        position: absolute;
                        top: 50px;
                        right: 20px;
                        padding: 20px 15px;
                        max-width: 85%;
                        border-radius: 10px;
                        background: rgba(250, 250, 250, 0.5);
                        color: var(--color-primary);
                        font: 300 18px 'Poppins-Light', sans-serif;
                        z-index: 2;
                        text-align: center;
                        display: block;
    
                        &.d-none {
                            display: none;
                        }
                    }
                }

                .country-item:hover {
                    .img-content {
                        img {
                            transform: scale(1.01);
                            filter: revert;
                        }
                    }
                }
            }
        }
    }

    .button-area {
        width: 285px;
        height: 60px;

        .a-btn {
            font-size: 16px;
        }
    }
}
