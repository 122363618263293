.our-guests-comments {
    overflow: hidden;
    padding: 30px 0;

    .title {
        text-align: center;
        margin-bottom: 30px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .message {
            width: 210px;
            height: 165px;
            font-size: 11px;
            position: relative;
            z-index: 1;

            .text {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            &-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: var(--primary-supportive);
                margin-bottom: 5px;
            }

            .stars {
                display: flex;

                .star {
                    width: 15px;
                    height: 15px;
                    background: var(--color-accent-gold);
                    mask: url($our-guests-comments-sprite + '#star_yellow') no-repeat center;
                    mask-size: cover;
                }
            }
        }

        .messages-block {
            display: flex;
            position: relative;
        }

        .first-message {
            top: -15px;
            padding: 25px 15px 30px 30px;
            background: url($our-guests-comments-sprite + '#first-message') no-repeat center;
            background-size: cover;
        }

        .second-message {
            top: 25px;
            padding: 25px 30px 30px 15px;
            background: url($our-guests-comments-sprite + '#second-message') no-repeat center;
            background-size: cover;
        }

        .third-message {
            top: -30px;
            padding: 45px 15px 30px 30px;
            background: url($our-guests-comments-sprite + '#third-message') no-repeat center;
            background-size: cover;
        }

        .video-block {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
    
            .video-box {
                position: relative;
            }
    
            video, img {
                width: 205px;
                height: 280px;
                object-fit: cover;
                border-radius: 10px;
                cursor: pointer;
            }
        }

        .button-block {
            top: 15px;
            width: 210px;
            height: 165px;
            background: url($our-guests-comments-sprite + '#button-block') no-repeat center;
            background-size: cover;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
            padding-left: 15px;
            position: relative;

            .block-title {
                font-family: Architects-Daughter, sans-serif;
                font-size: 15px;
                padding: 0 35px;
                text-align: center;
                margin-bottom: 8px;
            }

            .all-reviews-btn {
                border-radius: 17px;
                font-size: 11px;
                text-transform: uppercase;
                color: var(--color-primary);
                background: var(--color-neutral-lightest);
                padding: 10px 25px;
                border: none;

                &:hover,
                &:active {
                    background-color: var(--color-primary);
                    color: var(--color-neutral-lightest);
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .our-guests-comments {
        padding: 50px 0;

        .title {
            font-size: 29px;
            margin-bottom: 50px;
        }
    
        .content {
            flex-direction: row;
            gap: 10px;
    
            .message {
                width: 267px;
                height: 210px;
                font-size: 15px;
    
                .message-title {
                    margin-bottom: 10px;
                }
    
                .stars {
                    .star {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
    
            .messages-block {
                flex-direction: column;
                gap: 20px;
            }
    
            .first-message {
                top: auto;
                padding-top: 35px;
            }
    
            .second-message {
                top: auto;
                padding: 50px 25px 30px 30px;
                background: url($our-guests-comments-sprite + '#third-message') no-repeat center;
                background-size: cover;
            }
    
            .third-message {
                top: auto;
                padding: 30px 24px 40px 20px;
                background: url($our-guests-comments-sprite + '#second-message') no-repeat center;
                background-size: cover;
            }
    
            .video-block {
                video, img {
                    width: 255px;
                    height: 350px;
                    border-radius: 10px;
                }
            }
    
            .button-block {
                top: auto;
                width: 262px;
                height: 206px;
    
                .block-title {
                    font-size: 20px;
                    padding: 0 35px;
                }
    
                .all-reviews-btn {
                    font-size: 20px;
                    padding: 15px 35px;
                    border-radius: 25px;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .our-guests-comments {

        .content {
    
            .message {
                width: 290px;
                height: 230px;
    
                .message-title {
                    margin-bottom: 25px;
                }
    
                .stars {
                    .star {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
    
            .messages-block {
                gap: 25px;
            }

    
            .video-block {
                video, img {
                    width: 382px;
                    height: 525px;
                    border-radius: 15px;
                }
            }
    
            .button-block {
                width: 275px;
                height: 216px;
            }
        }
    }
}