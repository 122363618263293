.plan-your-experience {
    padding-top: 30px;
    padding-bottom: 50px;

    .route {
        width: 360px;
        height: 58px;
        display: block;
        background-color: var(--color-accent-rose);
        mask: url($general-icons-sprite + '#route') no-repeat center;
        mask-size: cover;
        position: absolute;
        right: -15px;
        bottom: -43px;
        transform: scale(-1, 1);
    }

    .title-block {
        position: relative;
        margin-bottom: 65px;

        .text-title {
            display: block;
            width: 195px;
            margin: 0 auto;
            text-align: center;
            font: 500 17px Architects-Daughter,sans-serif;
            transform: rotate(-3deg);
        }
    }

    .a-btn {
        display: block;
        width: 301px;
        border-radius: 20px;
        text-align: center;
        margin: 0 auto;
    }
}

@media screen and (min-width: 768px) {
    .plan-your-experience {
        padding-top: 70px;
        padding-bottom: 50px;

        .route {
            width: 410px;
            height: 70px;
            transform: none;
            right: auto;
            left: -410px;
            bottom: -10px;
        }

        .title-block {
            margin: 0 auto 65px;
            width: 310px;
    
            .text-title {
                width: 310px;
                font-size: 24px;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .plan-your-experience {
        .route {
            width: 600px;
            height: 85px;
            left: -600px;
            bottom: -20px;
        }
    }
}

@media screen and (min-width: 1600px) {
    .plan-your-experience {
        .route {
            width: 810px;
            height: 110px;
            left: -810px;
            bottom: -30px;
        }
    }
}