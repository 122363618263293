.reviews {
    padding: 50px 0 0 0;
    background-color: transparent;

    .title {
        margin-bottom: 50px;
    }

    .reviews-list {
        margin: 0;
        gap: 30px;

        &__item {
            width: calc(100% / 2 - 15px);
            box-shadow: none;
            padding: 30px 0;
            margin-bottom: 0;
            text-align: left;

            .review-stars-list {
                justify-content: left;

                .icon-star {
                    width: 22px;
                    height: 17px;
                }
            }

            .review-description-container {
                .review-description {
                    line-height: 21px;
                    text-align: justify;
    
                    @media screen and (min-width: 992px) {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
                
                .show-more-btn {
                    align-self: flex-end;
                }
            }

            .review-date {
                font-size: 16px;
            }

            .review-city {
                font-size: 16px;
            }

            .review-author {
                 font-size: 16px;
            }
        }
    }

    .see-more-reviews-btn {
        margin: 60px auto;
        max-width: 270px;
    }

    .load-more-reviews {
        display: inline-block;

        .arrow-down {
            position: absolute;
            top: 3px;
            left: 179px;
        }
    }

    .reviews-link {
        text-decoration: underline;
        border: 0;
        margin: 80px 0 0 0;
        justify-content: flex-start;
        font-family: Poppins-SemiBold,sans-serif;

        &:hover {
            color: var(--blue-basic);
            background-color: var(--grey-light-basic);
        }
    }
}

.card-reviews {
    border: 0;
    font-size: 17px;

    .card-header {
        flex-direction: row;

        .reviews-info {
            flex-direction: column;
            font-size: 16px;
            text-align: left;
            text-align: right;

            .reviews-info-name {
                font: 600 18px 'Poppins-SemiBold', sans-serif;
            }
        }
    }

    .btn-show-more {
        font-size: 18px;
    }
}