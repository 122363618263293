.section-customer-stories {
    padding: 30px 0;
    background-color: var(--color-supportive);
    color: var(--color-text);

    .container-l {
        overflow: hidden;
    }

    .title-box {
        text-align: center;

        .description {
            font-family: 'Poppins-Medium';
            font-weight: 500;
            margin-bottom: 0;
            font-size: 15px;
        }
    }

    .customer-stories-slider {
        display: flex;
        align-items: stretch;
        gap: 10px;
        margin: 0 -15px;

        .swiper-wrapper {
            display: flex;
            padding: 10px 0;
        }

        .swiper-slide {
            width: auto;
        }

        .stories-slider-item {
            flex-shrink: 0;
            height: auto;
            display: flex;
            flex-direction: column;
            height: 100%;
            flex-grow: 1;

            &.card-rotate-left {
                transform: rotate(-1deg);
            }

            &.card-rotate-right {
                transform: rotate(3deg);
            }
        }
    }

    .section-footer {
        display: flex;
        flex-direction: column;
        align-items: center;

        .btn-action-text {
            display: block;
            font: 500 18px 'Architects-Daughter', sans-serif;
            position: relative;
            padding: 10px 55px 20px 0;
            transform: rotate(-2deg);
            max-width: 306px;
            text-align: center;
            margin-right: 15%;

            &::after {
                content: '';
                display: block;
                width: 75px;
                height: 75px;
                background-color: var(--blue-basic);
                mask: url($customer-stories-section-sprite + '#arrow') no-repeat center;
                mask-size: contain !important;
                transform: rotate(10deg);
                position: absolute;
                top: 15px;
                right: -14px;
            }
        }
    }

    .stories-card {
        border-radius: 10px;
        overflow: hidden;
        background-color: var(--white);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        height: 100%;
        max-width: 200px;

        .stories-card-img {
            padding: 9px;
            width: 100%;
            height: 183px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .stories-card-title {
            padding: 0 10px 15px;
            margin-bottom: 0;
            font: 400 12px 'Architects-Daughter', sans-serif;
            text-align: center;
            flex-grow: 1;
        }
    }
}

@media screen and (min-width: 1024px) {
    .section-customer-stories {
        padding: 50px 0;

        .title-box {
            display: flex;
            align-items: center;
            gap: 160px;
            margin-bottom: 30px;

            .title {
                margin-bottom: 0;
            }

            .description {
                font-size: 20px;
            } 
        }

        .customer-stories-slider {
            margin: 0 -15px 0 0;

            .stories-card {
                max-width: 400px;

                .stories-card-img {
                    padding: 14px;
                    height: 280px;
                }
                
                .stories-card-title {
                    font-size: 22px;
                    padding: 10px 30px 25px;
                }
            } 
        }

        .section-footer {
            flex-direction: row;
            justify-content: center;
            gap: 10px;
            padding-top: 30px;

            .btn-action-text {
                margin-right: 0;
                font-size: 26px;
                padding: 10px 110px 10px 0;
                transform: rotate(-3deg);
                max-width: 510px;
                
                &::after {
                    height: 150px;
                    width: 150px;
                    mask: url($customer-stories-section-sprite + '#arrow5') no-repeat center;
                    transform: rotate(4deg);
                    top: auto;
                    bottom: -50px;
                    right: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .section-customer-stories {
        .title-box {
            gap: 250px;

            .title {
                margin-bottom: 0;
            }

            .description {
                font-size: 25px;
            } 
        }

        .container-l {
            padding-right: 0 !important;
        }

        .customer-stories-slider {
            margin: 0;
            padding-left: 100px;

            .stories-card {
                .stories-card-img {
                    padding: 14px;
                    height: 320px;
                }
                
                .stories-card-title {
                    font-size: 20px;
                    padding: 10px 20px 20px;
                }
            } 
        }

        .section-footer {

            .btn-outline {
                font-size: 26px;
                padding: 15px 30px;
                border-radius: 30px;
            }
        }
    }
}