.icon {
    width: 20px;
    height: 21px;
    display: inline-block;
    background-color: var(--white);
    mask: url($host-details-popup + '#bookmark') no-repeat center;

    @media screen and (min-width: 1440px) {
        width: 25px;
        height: 26px;
    }
}

.icon-knows-every-hidden-gem {
    mask: url($host-details-popup + '#find-on-map') no-repeat center;
}

.icon-loves-food-local-culture {
    mask: url($host-details-popup + '#noodle') no-repeat center;
}

.icon-cultural-stories-local-insights {
    mask: url($host-details-popup + '#bookmark') no-repeat center;
}

.icon-knows-the-city-inside-out {
    mask: url($host-details-popup + '#direction') no-repeat center;
}

.icon-brings-stories-places-life {
    mask: url($host-details-popup + '#speech-bubble') no-repeat center;
}

.icon-architectural-cultural-explorer {
    mask: url($host-details-popup + '#skyline') no-repeat center;
}

.icon-nature-city-explorer {
    mask: url($host-details-popup + '#building') no-repeat center;
}

.icon-local-historian {
    mask: url($host-details-popup + '#guide-book') no-repeat center;
}

.icon-eco-friendly-advocate {
    mask: url($host-details-popup + '#ecology') no-repeat center;
}

.icon-photo-spots-explorer {
    mask: url($host-details-popup + '#photo-pin') no-repeat center;
}

.icon-art-creative-spaces-explorer {
    mask: url($host-details-popup + '#map-bulb') no-repeat center;
}

.icon-chevron {
    display: inline-block;
    width: 19px;
    height: 12px;
    mask: url($host-details-popup + '#icon-arrow-down') no-repeat center;
    transition: color 0.3s linear, background-color 0.3s linear;
}

.compass {
    display: inline-block;
    width: 25px;
    height: 25px;
    mask: url($host-details-popup + '#compass') no-repeat center;
}

.noodle {
    display: inline-block;
    width: 15px;
    height: 16px;
    mask: url($host-details-popup + '#noodle') no-repeat center;
}

.icon-binoculars {
    display: inline-block;
    width: 15px;
    height: 13px;
    mask: url($host-details-popup + '#icon-binoculars') no-repeat center;
}

.icon-city {
    width: 15px;
    height: 13px;
    mask: url($host-details-popup + '#icon-city') no-repeat center;
}

.icon-flag {
    display: inline-block;
    width: 15px;
    height: 12px;
    mask: url($host-details-popup + '#icon-flag') no-repeat center;
}

.language {
    display: inline-block;
    width: 25px;
    height: 25px;
    mask: url($host-details-popup + '#language') no-repeat center;
}

.pin-with-hard {
    width: 25px;
    height: 25px;
    mask: url($host-details-popup + '#pin-with-hard') no-repeat center;
}